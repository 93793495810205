<template>
  <div id="booking" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">{{ mode }} Booking</div>
        <div class="tag tagWhite" v-if="mode == 'Edit'">
          <!-- Status : {{ itemsDataBooking.bookingstatus.bs_name }} -->
          Status : {{ bookingStatus }}
        </div>
      </div>
    </div>
    <div id="ownerContent" class="wrap-Main">
      <div class="box-S4 flex-between-center noPadding">
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="panal-title">Create By</div>
            <div v-if="itemsDataBooking.cb != null">
              {{ itemsDataBooking.cb }}
            </div>
            <div v-else>-</div>
            <div v-if="itemsDataBooking.cb != null">
              {{ itemsDataBooking.cd | yyyyMMdd }}
              {{ itemsDataBooking.cd | HHmm }}
            </div>
            <div v-else>-</div>
          </div>
        </div>
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="panal-title">Edit By</div>
            <div v-if="itemsDataBooking.mb != null">
              {{ itemsDataBooking.mb }}
            </div>
            <div v-else>-</div>
            <div v-if="itemsDataBooking.mb != null">
              {{ itemsDataBooking.md | yyyyMMdd }}
              {{ itemsDataBooking.md | HHmm }}
            </div>
            <div v-else>-</div>
          </div>
        </div>
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="panal-title">Approve By</div>
            <div v-if="itemsDataBooking.b_approve != null">
              {{ itemsDataBooking.b_approve }}
            </div>
            <div v-else>-</div>
            <div v-if="itemsDataBooking.b_approve != null">
              {{ itemsDataBooking.b_approve_date | yyyyMMdd }}
              {{ itemsDataBooking.b_approve_date | HHmm }}
            </div>
            <div v-else>-</div>
          </div>
        </div>
      </div>
      <div class="line marginTop"></div>
    </div>
    <div id="flightDetail" class="wrap-Main">
      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Booking Detail</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S2">
                <v-text-field label="Ref No." v-model="ref_id" disabled></v-text-field>
              </div>
              <div class="box-S2">
                <v-text-field label="Flight No." v-model="flightno" :disabled="readonly_eng ||
                  readonly_pilot ||
                  readonly_flight ||
                  readonly_super_flight
                  "></v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <!-- <v-select
                  :items="itemsListCompany"
                  v-model="company"
                  label="Customer"
                  item-text="o_name"
                  item-value="o_id"
                  return-object
                  readonly
                ></v-select> -->
                <v-select :items="itemsListCustomer" v-model="customer" label="Customer" item-text="o_name"
                  item-value="o_id" return-object disabled></v-select>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-menu v-model="menuselectdate" :close-on-content-click="false" :nudge-right="0" :nudge-top="10"
                  transition="scale-transition" offset-y :disabled="readonly_eng ||
                    readonly_pilot ||
                    readonly_flight ||
                    readonly_super_flight
                    ">
                  <template v-slot:activator="{ on }">
                    <v-text-field class="input-date" append-icon="mdi-calendar-range" v-model="date" label="Date" readonly
                      v-on="on" :disabled="readonly_eng ||
                        readonly_pilot ||
                        readonly_flight ||
                        readonly_super_flight
                        "></v-text-field>
                  </template>
                  <v-date-picker v-model="date" @input="menuselectdate = false" :disabled="readonly_eng ||
                    readonly_pilot ||
                    readonly_flight ||
                    readonly_super_flight
                    "></v-date-picker>
                </v-menu>
              </div>
              <div class="box-S2">
                <v-text-field append-icon="mdi-clock-outline" v-model="time" label="Time" type="time" :disabled="readonly_eng ||
                  readonly_pilot ||
                  readonly_flight ||
                  readonly_super_flight
                  "></v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <!-- <v-combobox class="hideMessage" :items="itemsListBookingType" label="Booking Type" v-model="bookingtype"
                  item-text="bt_name" item-value="bt_id" return-object :disabled="readonly_eng ||
                    readonly_pilot ||
                    readonly_flight ||
                    readonly_super_flight
                    "></v-combobox> -->
                <v-select class="hideMessage" :items="itemsListBookingType" label="Booking Type" v-model="bookingtype"
                  item-text="bt_name" item-value="bt_id" return-object :disabled="readonly_eng ||
                    readonly_pilot ||
                    readonly_flight ||
                    readonly_super_flight
                    "></v-select>
              </div>
              <div class="box-S2">
                <v-select class="hideMessage" :items="itemsListAirport" label="Departure From" v-model="departurefrom"
                  item-text="ap_name" item-value="ap_id" return-object :disabled="readonly_eng ||
                    readonly_pilot ||
                    readonly_flight ||
                    readonly_super_flight
                    "></v-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page flex-between-center">
            <div class>
              <span class="T-size-24">Destination</span>
            </div>
          </div>
        </div>
        <div class="B-destination-Main">
          <div class="B-destination" v-for="(item, index) in listAddLegs" :key="item.Idbookinglegs">
            <div class="b-destination-name">
              <div class="T-size-18 SemiBold">{{ item.legs.ap_name }}</div>
              <div class="tag tagWhite" v-show="item.activity5 == true">
                S/D
              </div>
              <div class="tag tagWhite" v-show="item.activity1 == true">
                S/D Refuel
              </div>
              <div class="tag tagWhite" v-show="item.activity2 == true">
                S/D Refuel and HLO familiarize
              </div>
              <div class="tag tagWhite" v-show="item.activity3 == true">
                S/D FOR JET A1 INSPECTION
              </div>
              <div class="tag tagWhite" v-show="item.activity4 == true">
                <!-- EDRILL-1 -->
                S/D for Helideck Inspec
              </div>
            </div>
            <div class="b-action">
              <div class="b-btn">
                <v-btn v-show="!(
                  readonly_eng ||
                  readonly_pilot ||
                  readonly_flight ||
                  readonly_super_flight
                )
                  " fab class="btn-edit" @click="EditItem(item, index)">
                  <span class="I-edit"></span>
                </v-btn>
                <v-btn v-show="!(
                  readonly_eng ||
                  readonly_pilot ||
                  readonly_flight ||
                  readonly_super_flight
                )
                  " fab class="primary btn-delete" @click="deleteItem(item)">
                  <span class="I-bin"></span>
                </v-btn>
              </div>
            </div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even" @click.stop="addItem()" v-show="!(
              readonly_eng ||
              readonly_pilot ||
              readonly_flight ||
              readonly_super_flight
            )
              ">
              <span class="I-create"></span>
              <span>Add</span>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>

    <!-- <CompAddPassengerOutbound
      :customer="customer.o_name"
      ref="CompAddPassengerOutbound"
    ></CompAddPassengerOutbound>
    <CompAddPassengerInbound
      :customer="customer.o_name"
      ref="CompAddPassengerInbound"
    ></CompAddPassengerInbound> -->

    <CompAddPassengerOutbound2 :customer="customer.o_name" :customer_id="customer.o_id" :bookingId="$route.params.id"
      ref="CompAddPassengerOutbound2" @addPassengerOutboundSuccess="AddPassengerSuccess"></CompAddPassengerOutbound2>
    <CompAddPassengerInbound2 :customer="customer.o_name" :customer_id="customer.o_id" :bookingId="$route.params.id"
      ref="CompAddPassengerInbound2" @addPassengerInboundSuccess="AddPassengerSuccess"></CompAddPassengerInbound2>

    <div id="statusReport" class="wrap-Main" v-if="mode == 'Edit'">
      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page flex-between-center">
            <div class>
              <span class="T-size-24">Status</span>
            </div>
            <div class="tag tagWhite">
              <!-- Status : {{ itemsDataBooking.bookingstatus.bs_name }} -->
              Status : {{ bookingStatus }}
            </div>
          </div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail">
            <div v-if="itemsDataBooking.b_submit_date == null && userdata.r_id == 2
              ">
              <v-btn class="theme-btn-even btn-report center Bsize190" @click="dialogSubmit = true">
                <span class="I-report"></span>
                <span class="T-size-18">Submit</span>
              </v-btn>
            </div>
            <div v-if="itemsDataBooking.b_submit_date != null">
              Submitted date :
              <span v-if="itemsDataBooking.b_submit_date != null">{{
                itemsDataBooking.b_submit_date | yyyyMMddHHmm
              }}</span><span v-else>-</span>
            </div>
            <div v-if="itemsDataBooking.b_accept_date == null &&
                (userdata.r_id == 4 || userdata.r_id == 21)
                ">
              <v-btn class="theme-btn-even btn-report center Bsize190" :disabled="itemsDataBooking.b_submit_date == null"
                @click="dialogAccept = true">
                <span class="I-report"></span>
                <span class="T-size-18">Accept</span>
              </v-btn>
            </div>
            <div v-if="itemsDataBooking.b_accept_date != null">
              Accepted date :
              <span v-if="itemsDataBooking.b_accept_date != null">{{
                itemsDataBooking.b_accept_date | yyyyMMddHHmm
              }}</span><span v-else>-</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Report Form Booking</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail">
            <v-btn v-if="sumPassengerOut != 0 || sumPassengerIn != 0" :disabled="itemsDataBooking.b_submit_date == null"
              class="theme-btn-even btn-report center Bsize190" @click="Booking_Report()">
              <span class="I-report"></span>
              <span class="T-size-18">Report</span>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div id="createFlightTransport" class="wrap-Main" v-show="mode == 'Edit'">
      <div class="box-S2" v-if="userdata.r_id == 4 || userdata.r_id == 21">
        <div class="box-S4">
          <div class="N-Page T-size-24">Flight</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail">
            <v-btn class="theme-btn-even btn-flight center Bsize190" v-if="ButtonGoToFlight"
              :disabled="ButtonCreateFlight" @click="GotoFlight()">
              <span class="I-flight"></span>
              <span class="T-size-18">Go to Flight</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-flight center Bsize190" v-if="!ButtonGoToFlight"
              :disabled="ButtonCreateFlight" @click="dialogConfirmToCreateFlight = true">
              <span class="I-flight"></span>
              <span class="T-size-18">Create Flight</span>
            </v-btn>
          </div>
        </div>
      </div>

      <!-- Dialog confirm to create flight -->
      <v-dialog v-model="dialogConfirmToCreateFlight" persistent max-width="300">
        <v-card id="dialog" class="TF type1">
          <div class="b-content">
            <div class="B-dialog save">
              <div class="t-title">Confirm To Create Flight ?</div>
              <div class="t-des">Are you sure to create flight?</div>
            </div>
            <div class="box-btn">
              <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogConfirmToCreateFlight = false"
                :disabled="buttonCancelCreateFlight">
                <span class="T-size-18">cancel</span>
              </v-btn>
              <v-btn class="theme-btn-even btn-save Bsize100" text @click="GotoCreateFlight()"
                :disabled="buttonSaveCreateFlight">
                <span class="T-size-18">Confirm</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>

      <div class="box-S2" v-if="bookingtype.bt_id == 2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Transportation</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail">
            <v-btn class="theme-btn-even btn-transport center Bsize190" v-if="!ButtonGoToTransport && userdata.r_id == 2"
              @click="dialogConfirmToCreateTransport = true" :disabled="readonly_eng ||
                readonly_pilot ||
                readonly_flight ||
                readonly_super_flight
                ">
              <span class="I-transport"></span>
              <span class="T-size-18">Create Transport</span>
            </v-btn>

            <v-btn class="theme-btn-even btn-transport center Bsize190" v-if="ButtonGoToTransport"
              @click="GotoTransport()">
              <span class="I-transport"></span>
              <span class="T-size-18">Go to Transport</span>
            </v-btn>
          </div>
        </div>
      </div>

      <!-- Dialog confirm to create transport -->
      <v-dialog v-model="dialogConfirmToCreateTransport" persistent max-width="300">
        <v-card id="dialog" class="TF type1">
          <div class="b-content">
            <div class="B-dialog save">
              <div class="t-title">Confirm To Create Transport ?</div>
              <div class="t-des">Are you sure to create transport?</div>
            </div>
            <div class="box-btn">
              <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogConfirmToCreateTransport = false"
                :disabled="buttonCancelCreateTranaport">
                <span class="T-size-18">cancel</span>
              </v-btn>
              <v-btn class="theme-btn-even btn-save Bsize100" text @click="GotoCreateTransportByBooking()"
                :disabled="buttonSaveCreateTranaport">
                <span class="T-size-18">Confirm</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>

      <div class="box-S2" v-if="itemsDataBooking.bs_id != 5">
        <div class="box-S4">
          <div class="N-Page T-size-24">Revise</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail">
            <v-btn v-show="userdata.r_id == 2" class="theme-btn-even btn-transport center"
              :disabled="itemsDataBooking.b_submit_date == null" @click="RevisePassenger()">
              <v-icon>mdi-autorenew</v-icon>
              <span class="T-size-18">Revise TO FLIGHT OPERATIONS</span>
            </v-btn>
            <div v-if="itemsDataBooking.b_revise_count > 0">
              #Revise {{ itemsDataBooking.b_revise_count }} :
              {{
                itemsDataBooking.b_revise_last_update.substring(0, 10) +
                " " +
                itemsDataBooking.b_revise_last_update.substring(11, 16)
              }}
            </div>
            <div v-else>No Revise</div>
          </div>
        </div>
      </div>

      <div class="line" v-if="bookingtype.bt_id == 2"></div>
    </div>
    <div id="partSave" class="wrap-Main">
      <div class="box-S4">
        <div class="b-btnSave">
          <v-btn class="theme-btn-even btn-cancle Bsize150" @click="GotoListBooking()" :disabled="readonly_eng ||
            readonly_pilot ||
            readonly_flight ||
            readonly_super_flight
            ">
            <span class="T-size-18">Cancel</span>
          </v-btn>
          <v-btn class="theme-btn-even btn-save Bsize150" @click="dialogSave = true" :disabled="readonly_eng ||
            readonly_pilot ||
            readonly_flight ||
            readonly_super_flight
            ">
            <span class="I-save"></span>
            <span class="T-size-18">Save</span>
          </v-btn>
        </div>
        <div class="b-btnDelete" v-show="mode == 'Edit'">
          <v-btn class="theme-btn-even btn-delete Bsize150" @click="dialogDelete = true" :disabled="readonly_eng ||
            readonly_pilot ||
            readonly_flight ||
            readonly_super_flight
            ">
            <span class="T-size-18">delete booking</span>
          </v-btn>
        </div>
      </div>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="GotoListBooking()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>

    <!-- dialog สำหรับ เพิ่ม แก้ไข -->

    <v-dialog v-model="dialogEvent" persistent max-width="400">
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <div class="B-dialogform">
            <div class="t-namepage">{{ formTitle }} Leg</div>
            <div class="b-form">
              <!-- <v-combobox class="hideMessage" :items="itemsListAirport" label="Airport" v-model="legs" item-text="ap_name"
                item-value="ap_id" return-object></v-combobox> -->
              <v-select class="hideMessage" :items="itemsListAirport" label="Airport" v-model="legs" item-text="ap_name"
                item-value="ap_id" return-object></v-select>
              <div class="b-checkbox">
                <v-checkbox v-model="activity5" label="S/D"></v-checkbox>
                <v-checkbox v-model="activity1" label="S/D Refuel"></v-checkbox>
                <v-checkbox v-model="activity2" label="S/D Refuel and HLO familiarize"></v-checkbox>
                <v-checkbox v-model="activity3" label="S/D FOR JET A1 INSPECTION"></v-checkbox>
                <!-- <v-checkbox v-model="activity4" label="EDRILL-1"></v-checkbox> -->
                <v-checkbox v-model="activity4" label="S/D for Helideck Inspec"></v-checkbox>
              </div>
            </div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogEvent = false">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-save Bsize100" text @click="SaveLegs()">
              <span class="T-size-18">Finish</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="dialog" persistent max-width="400">
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <div class="B-dialogform">
            <div class="t-namepage">Edit Leg</div>
            <div class="b-form">
              <v-select class="hideMessage" label="Airport"></v-select>
              <div class="b-checkbox">
                <v-checkbox v-model="activity1" label="S/D Refuel"></v-checkbox>
                <v-checkbox
                  v-model="activity2"
                  label="S/D Refuel and HLO familiarize"
                ></v-checkbox>
                <v-checkbox
                  v-model="activity3"
                  label="S/D FOR JET A1 INSPECTION"
                ></v-checkbox>
                <v-checkbox
                  v-model="activity4"
                  label="S/D for Helideck Inspec"
                ></v-checkbox>
              </div>
            </div>
          </div>
          <div class="box-btn">
            <v-btn
              class="theme-btn-even btn-cancel Bsize100"
              text
              @click="dialog = false"
            >
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn
              class="theme-btn-even btn-save Bsize100"
              text
              @click="dialog = false"
            >
              <span class="T-size-18">Save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog> -->

    <!-- Dialog confirm save -->
    <v-dialog v-model="dialogSave" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog save">
            <div class="t-title">Confirm To Save ?</div>
            <div class="t-des">Are you sure to save this item?</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogSave = false" :disabled="buttonCancel">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-save Bsize100" v-if="mode == 'Create'" text @click="CreateBooking()"
              :disabled="buttonSave">
              <span class="T-size-18">Save</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-save Bsize100" v-else text @click="SaveEditToDB()" :disabled="buttonSave">
              <span class="T-size-18">Save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirm" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog confirm">
            <div class="t-title">Save Completed</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-ok Bsize100" text @click="SaveCompleted()">
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog delete">
            <div class="t-title">Confirm Deletion</div>
            <div class="t-des">
              Are you sure to permanently delete this item?
            </div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogDelete = false">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-delete Bsize100" text @click="DeleteBooking()">
              <span class="T-size-18">Delete</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Dialog confirm submit -->
    <v-dialog v-model="dialogSubmit" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog save">
            <div class="t-title">Confirm To Submit ?</div>
            <div class="t-des">Are you sure to submit this item?</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogSubmit = false">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-save Bsize100" text @click="Submit()">
              <span class="T-size-18">Submit</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Dialog confirm accept -->
    <v-dialog v-model="dialogAccept" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog save">
            <div class="t-title">Confirm To Accept ?</div>
            <div class="t-des">Are you sure to accept this item?</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogSubmit = false">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-save Bsize100" text @click="Accept()">
              <span class="T-size-18">Accept</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import CompAddPassengerInbound from "../components/booking/CompAddPassengerInbound";
import CompAddPassengerOutbound from "../components/booking/CompAddPassengerOutbound";
import CompAddPassengerInbound2 from "../components/booking/CompAddPassengerInbound2";
import CompAddPassengerOutbound2 from "../components/booking/CompAddPassengerOutbound2";

import CompAddPassengerInboundFlight from "../components/flight/CompAddPassengerInboundFlight";
import CompAddPassengerOutboundFlight from "../components/flight/CompAddPassengerOutboundFlight";
import { startOfDay, endOfDay, format, addHours } from "date-fns";
import fillerdate from "../utils/filterdate";
import { faIR } from "date-fns/locale";

export default {
  name: "BookingDetail",
  data: () => ({
    dialogConfirmToCreateFlight: false,
    buttonCancelCreateFlight: false,
    buttonSaveCreateFlight: false,

    dialogConfirmToCreateTransport: false,
    buttonCancelCreateTranaport: false,
    buttonSaveCreateTranaport: false,

    mode: "",
    itemsDataBooking: [],
    itemsDataLegs: [],
    itemsListAirport: [],
    itemsListBookingType: [],
    // itemsListCompany: [],
    // company: "",
    itemsListCustomer: [],
    customer: "",
    chooseItemLegs: null,
    chooseItemLegIndex: null,
    listAddLegs: [],
    activity1: false,
    activity2: false,
    activity3: false,
    activity4: false,
    activity5: false,
    menuselectdate: false,
    menuselecttime: false,
    dialog: false,
    dialogEvent: false,
    dialogSave: false,
    dialogConfirm: false,
    dialogDelete: false,
    dialogSubmit: false,
    dialogAccept: false,
    modeEvent: "",
    flightno: "",

    date: "",
    time: "",
    bookingtype: "",
    departurefrom: "",
    legs: "",
    idbook: "",
    ref_id: "",
    userdata: "",
    dateNow: new Date(),
    ////////////////REPORT///////////////
    ArrListPassenger: [],
    ArrPassLeg_Out: [],
    ArrPassLeg_In: [],

    //Add
    ButtonCreateFlight: true,
    ButtonGoToFlight: false,

    thisFlight: 0,

    thisTransport: 0,
    ButtonGoToTransport: false,

    readonly_flight: false,
    readonly_super_flight: false,
    readonly_pilot: false,
    readonly_eng: false,
    readonly_shore: false,

    buttonCancel: false,
    buttonSave: false,

    sumPassengerOut: 0,
    sumPassengerIn: 0,

    bookingStatus: "",
  }),
  props: [],
  components: {
    // CompAddPassengerInbound,
    // CompAddPassengerOutbound,
    CompAddPassengerInbound2,
    CompAddPassengerOutbound2,
  },
  async mounted() {
    this.userdata = await this.$CurrentUser.GetCurrentUser();
    await this.enable_disable();

    this.mode = await this.$route.params.mode;
    this.idbook = await this.$route.params.id;

    await this.SelectListCompany();
    await this.SelectListAirport();
    await this.SelectListBookingType();

    this.RenderUI();
  },
  computed: {
    formTitle() {
      return this.modeEvent === "create" ? "Add" : "Edit";
    },
  },
  methods: {
    async RenderUI() {
      //Add
      this.buttonCancel = false;
      this.buttonSave = false;

      if (this.mode == "Edit") {
        await this.DataBookingFromDB();
        await this.DataLegsFromDB();
        this.DataPassengerFromDB();
      } else if (this.mode == "Create") {
        //Create booking default
        //Generate ref_id
        this.date = format(this.dateNow, "yyyy-MM-dd");
        var querybooking = {};
        querybooking.b_departure_date = {
          $gt: format(startOfDay(new Date(this.date)), "yyyy-MM-dd HH:mm:ss"),
          $lt: format(endOfDay(new Date(this.date)), "yyyy-MM-dd HH:mm:ss"),
        };
        var datacountbooking = await feathersClientUOA
          .service("booking")
          .find({ query: querybooking });
        let countbooking = datacountbooking.data.filter(
          (x) => x.b_departure_date.substr(0, 10) == this.date
        );
        let numref = 0;
        if (countbooking.length < 10) {
          numref = "000" + (parseInt(countbooking.length) + 1);
        } else {
          numref = "00" + (parseInt(countbooking.length) + 1);
        }
        this.ref_id = this.date.replace("-", "").replace("-", "") + numref;
        var newBookingDefault = {
          c_id: this.userdata.c_id,
          o_id: this.userdata.o_id,
          f_no: "",
          b_departure_date: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
          ap_id: 1,
          b_submit_date: null,
          b_accept_date: null,
          bs_id: 1,
          bt_id: 1,
          f_id: null,
          ts_id: null,
          b_revise: null,
          cb: this.userdata.FullName,
          cd: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
          ref_id: this.ref_id,
          b_approve: null,
          b_approve_date: null,
          //o_id: this.userdata.o_id,
        };

        var res = await feathersClientUOA
          .service("booking")
          .create(newBookingDefault);
        this.idbook = res.b_id;

        await this.DataBookingFromDB();
        this.mode = "Edit";
        //Edit
        //this.$router.push({ path: "/BookingDetail/Edit/" + res.b_id });
        var url = "#/BookingDetail/Edit/" + res.b_id;
        window.location.replace(url);
      }
    },

    async enable_disable() {
      if (this.userdata.r_id == 4) {
        this.readonly_flight = true;
      } else if (this.userdata.r_id == 9) {
        this.readonly_eng = true;
      } else if (this.userdata.r_id == 12) {
        this.readonly_pilot = true;
      } else if (this.userdata.r_id == 2) {
        this.readonly_shore = false;
      } else if (this.userdata.r_id == 21) {
        this.readonly_super_flight = true;
      }
    },

    async DataBookingFromDB() {
      try {
        var q = {};
        q.b_id = this.idbook;
        q.$eager = "[bookingstatus, customer, bookingtype, airport]";
        var res = await feathersClientUOA.service("booking").find({ query: q });
        this.itemsDataBooking = res.data[0];
        if (this.itemsDataBooking.bookingstatus.bs_name != "") {
          this.bookingstatus = this.itemsDataBooking.bookingstatus.bs_name;
        }

        this.flightno = this.itemsDataBooking.f_no;

        //this.company = this.itemsDataBooking.c_id;
        //Edit
        //this.company = { o_id: this.itemsDataBooking.c_id };
        //Edit2
        this.customer = {
          o_id: this.itemsDataBooking.customer.o_id,
          o_name: this.itemsDataBooking.customer.o_name,
        };
        this.date = this.itemsDataBooking.b_departure_date.substring(0, 10);
        this.time = this.itemsDataBooking.b_departure_date.substring(11, 16);

        this.bookingtype = {
          bt_id: this.itemsDataBooking.bookingtype.bt_id,
          bt_name: this.itemsDataBooking.bookingtype.bt_name,
        };

        // this.departurefrom = this.itemsDataBooking.ap_id;
        this.departurefrom = {
          ap_id: this.itemsDataBooking.ap_id,
          ap_name: this.itemsDataBooking.airport.ap_name,
        };

        this.ref_id = this.itemsDataBooking.ref_id;
        //Show only flight off
        if (this.userdata.r_id == 4 || this.userdata.r_id == 21) {
          //Show only booking status == 3 (shore Submit)
          if (this.itemsDataBooking.bookingstatus.bs_id == 3) {
            //Button Create Flight show
            this.ButtonCreateFlight = false;
            if (this.itemsDataBooking.f_id != null) {
              this.thisFlight = this.itemsDataBooking.f_id;
              this.ButtonGoToFlight = true;
            }
          } else {
            this.ButtonCreateFlight = true;
          }
        }

        if (this.itemsDataBooking.ts_id != null) {
          this.thisTransport = this.itemsDataBooking.ts_id;
          this.ButtonGoToTransport = true;
        }

        this.bookingStatus = this.itemsDataBooking.bookingstatus.bs_name;
      } catch (error) {
        console.log(
          "DataBookingFromDB ไม่สามารถขอข้อมูลจาก server ได้ /nError : " + error
        );
      }
    },
    async DataLegsFromDB() {
      this.listAddLegs = [];
      try {
        var q = {};
        q.b_id = this.idbook;
        //น้อยไปมาก
        q.$sort = {
          bl_id: 1,
        };
        q.$eager = "[airport]";
        q.retire = "0";
        var res = await feathersClientUOA
          .service("bookingleg")
          .find({ query: q });
        this.itemsDataLegs = res.data;
        this.itemsDataLegs.forEach((element) => {
          var activity1 = false;
          if (element.bl_activity_1 == "1") {
            activity1 = true;
          } else {
            activity1 = false;
          }
          var activity2 = false;
          if (element.bl_activity_2 == "1") {
            activity2 = true;
          } else {
            activity2 = false;
          }
          var activity3 = false;
          if (element.bl_activity_3 == "1") {
            activity3 = true;
          } else {
            activity3 = false;
          }
          var activity4 = false;
          if (element.bl_activity_4 == "1") {
            activity4 = true;
          } else {
            activity4 = false;
          }
          var activity5 = false;
          if (element.bl_activity_5 == "1") {
            activity5 = true;
          } else {
            activity5 = false;
          }
          var newArrayBinding = {};
          newArrayBinding["activity1"] = activity1;
          newArrayBinding["activity2"] = activity2;
          newArrayBinding["activity3"] = activity3;
          newArrayBinding["activity4"] = activity4;
          newArrayBinding["activity5"] = activity5;
          newArrayBinding["Idbookinglegs"] = element.bl_id;
          newArrayBinding["legs"] = element.airport;
          this.listAddLegs.push(newArrayBinding);
        });
      } catch (error) {
        console.log(
          "DataLegsFromDB ไม่สามารถขอข้อมูลจาก server ได้ /nError : " + error
        );
      }
    },
    async DataPassengerFromDB() {
      try {
        this.sumPassengerIn =
          await this.$refs.CompAddPassengerInbound2.DataPassengerFromDB(
            this.idbook
          );
      } catch (error) {
        console.log(error);
      }
      try {
        this.sumPassengerOut =
          await this.$refs.CompAddPassengerOutbound2.DataPassengerFromDB(
            this.idbook
          );
      } catch (error) {
        console.log(error);
      }
    },
    async SelectListAirport() {
      try {
        var q = {};
        q.retire = 0;
        var res = await feathersClientUOA
          .service("airports")
          .find({ query: q });
        this.itemsListAirport = res.data;
      } catch (error) {
        console.log(
          "SelectListAirport ไม่สามารถขอข้อมูลจาก server ได้ /nError : " + error
        );
      }
    },
    async SelectListBookingType() {
      try {
        var res = await feathersClientUOA.service("bookingtype").find({});
        this.itemsListBookingType = res.data;
      } catch (error) {
        console.log(
          "SelectListBookingType ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
          error
        );
      }
    },
    async SelectListCompany() {
      try {
        // var res = await feathersClientUOA.service("company").find({});
        //Edit company => customer
        var res = await feathersClientUOA.service("customer").find({});
        this.itemsListCustomer = res.data;
      } catch (error) {
        console.log(
          "SelectListCompany ไม่สามารถขอข้อมูลจาก server ได้ /nError : " + error
        );
      }
    },
    addItem() {
      this.modeEvent = "create";
      this.activity1 = false;
      this.activity2 = false;
      this.activity3 = false;
      this.activity4 = false;
      this.activity5 = false;

      //Add ดัก bl_dest_airport_id != null ไม่งั้นดึงมาแสดงจะไม่ขึ้นและตอน add leg ใหม่จะแอดไม่ได้ และตอนสร้าง flight จาก booking จะพัง
      this.legs = this.itemsListAirport[0];
      this.dialogEvent = true;
    },
    EditItem(itemlegs, index) {
      this.modeEvent = "edit";
      this.dialogEvent = true;
      this.legs = itemlegs.legs;
      this.activity1 = itemlegs.activity1;
      this.activity2 = itemlegs.activity2;
      this.activity3 = itemlegs.activity3;
      this.activity4 = itemlegs.activity4;
      this.activity5 = itemlegs.activity5;
      this.chooseItemLegs = itemlegs;
      this.chooseItemLegIndex = index;
    },
    async deleteItem(itemlegs) {
      try {
        await feathersClientUOA.service("bookingleg").remove(itemlegs.Idbookinglegs);
        //SEQ LOG
        let properties = {
          bl_id: itemlegs.Idbookinglegs,
          b_id: parseInt(this.idbook)
        };
        this.$log.Info('{action} by {username} ({role}) : b_id={b_id}', { action: "DeleteLegFromBooking", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("DeleteLegFromBooking, Error : {error}", { error });
        console.log("DeleteLegFromBooking, Error : {error}" + error);
      }
      this.DataLegsFromDB();
    },
    async SaveLegs() {
      if (this.modeEvent == "create") {
        if (this.legs != "" && this.legs != null && this.legs != undefined) {
          let DataAdd = {
            bl_dest_airport_id: this.legs.ap_id,
            b_id: this.idbook,
            bl_activity_1: this.activity1 === false ? 0 : 1,
            bl_activity_2: this.activity2 === false ? 0 : 1,
            bl_activity_3: this.activity3 === false ? 0 : 1,
            bl_activity_4: this.activity4 === false ? 0 : 1,
            bl_activity_5: this.activity5 === false ? 0 : 1,
            retire: 0,
          };

          if (DataAdd.bl_dest_airport_id != undefined) {
            await this.CreateBookingLegs(DataAdd);
          }
        } else {
          alert("เกิดข้อผิดพลาด กรุณา Refesh หน้าเว็บและลองใหม่อีกครั้ง");
        }
      } else {
        let dataUpdate = {
          bl_dest_airport_id: this.legs.ap_id,
          bl_activity_1: this.activity1 === false ? 0 : 1,
          bl_activity_2: this.activity2 === false ? 0 : 1,
          bl_activity_3: this.activity3 === false ? 0 : 1,
          bl_activity_4: this.activity4 === false ? 0 : 1,
          bl_activity_5: this.activity5 === false ? 0 : 1,
          retire: 0,
        };

        let Idbookinglegs = this.listAddLegs[this.chooseItemLegIndex].Idbookinglegs;
        await this.Updatetodb(Idbookinglegs, dataUpdate);
      }
      this.DataLegsFromDB();
      this.dialogEvent = false;
    },
    async CreateBooking() {
      try {
        var querybooking = {};
        querybooking.b_departure_date = {
          $gt: format(startOfDay(new Date(this.date)), "yyyy-MM-dd HH:mm:ss"),
          $lt: format(endOfDay(new Date(this.date)), "yyyy-MM-dd HH:mm:ss"),
        };
        var datacountbooking = await feathersClientUOA
          .service("booking")
          .find({ query: querybooking });
        let countbooking = datacountbooking.data.filter(
          (x) => x.b_departure_date.substr(0, 10) == this.date
        );
        let numref = 0;
        if (countbooking.length < 10) {
          numref = "000" + (parseInt(countbooking.length) + 1);
        } else {
          numref = "00" + (parseInt(countbooking.length) + 1);
        }
        this.ref_id = this.date.replace("-", "").replace("-", "") + numref;

        var DataAdd = {
          c_id: this.userdata.c_id,
          o_id: this.customer.o_id,
          f_no: this.flightno,
          b_departure_date: this.date + " " + this.time,
          bt_id: this.bookingtype.bt_id,
          ap_id: this.departurefrom.ap_id,
          bs_id: 1,
          ref_id: this.ref_id,
          cb: this.userdata.FullName,
          cd: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
        };

        var res = await feathersClientUOA.service("booking").create(DataAdd);
        this.idbook = res.b_id;
        this.CreateBookingLegsRawData(res.b_id);
        this.CreateBookingPassenger(res.b_id);
        this.dialogSave = false;
        this.dialogConfirm = true;
      } catch (error) {
        console.log(error);
      }
    },
    async CreateBookingLegsRawData(DataBooking) {
      try {
        this.listAddLegs.forEach((element) => {
          var activity1 = 0;
          if (element.activity1 == true) {
            activity1 = 1;
          } else {
            activity1 = 0;
          }
          var activity2 = 0;
          if (element.activity2 == true) {
            activity2 = 1;
          } else {
            activity2 = 0;
          }
          var activity3 = 0;
          if (element.activity3 == true) {
            activity3 = 1;
          } else {
            activity3 = 0;
          }
          var activity4 = 0;
          if (element.activity4 == true) {
            activity4 = 1;
          } else {
            activity4 = 0;
          }
          var activity5 = 0;
          if (element.activity5 == true) {
            activity5 = 1;
          } else {
            activity5 = 0;
          }

          var DataAdd = {
            bl_dest_airport_id: element.legs.ap_id,
            b_id: DataBooking,
            bl_activity_1: activity1,
            bl_activity_2: activity2,
            bl_activity_3: activity3,
            bl_activity_4: activity4,
            bl_activity_5: activity5,
          };

          this.CreateBookingLegs(DataAdd);
        });
      } catch (error) {
        console.log(error);
      }
    },
    async CreateBookingLegs(DataAdd) {
      try {
        var res = await feathersClientUOA.service("bookingleg").create(DataAdd);
        //SEQ LOG
        let properties = {
          b_id: parseInt(DataAdd.b_id),
          bl_id: res.bl_id,
          bl_dest_airport_id: DataAdd.bl_dest_airport_id,
          bl_activity_1: DataAdd.bl_activity_1,
          bl_activity_2: DataAdd.bl_activity_2,
          bl_activity_3: DataAdd.bl_activity_3,
          bl_activity_4: DataAdd.bl_activity_4,
          bl_activity_5: DataAdd.bl_activity_5
        };
        this.$log.Info('{action} by {username} ({role}) : b_id={b_id}, bl_id={bl_id}', { action: "AddLegToBooking", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("AddLegToBooking, Error : {error}", { error });
        console.log("AddLegToBooking, Error : {error}" + error);
      }
    },
    async CreateBookingPassenger(DataBooking) {
      try {
        await this.$refs.CompAddPassengerInbound2.CreateBookingPassengerRawData(
          DataBooking
        );
      } catch (error) {
        console.log(error);
      }
      try {
        await this.$refs.CompAddPassengerOutbound2.CreateBookingPassengerRawData(
          DataBooking
        );
      } catch (error) {
        console.log(error);
      }
    },
    async SaveEditToDB() {
      try {
        //Add
        this.buttonCancel = true;
        this.buttonSave = true;

        var DataAdd = {
          o_id: this.customer.o_id,
          f_no: this.flightno,
          b_departure_date: this.date + " " + this.time,
          bt_id: this.bookingtype.bt_id,
          ap_id: this.departurefrom.ap_id,
          mb: this.userdata.FullName,
          md: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
          bs_id: this.itemsDataBooking.bs_id,
          b_submit_date: this.itemsDataBooking.b_submit_date,
          b_accept_date: this.itemsDataBooking.b_accept_date,
          b_approve: this.itemsDataBooking.b_approve,
          b_approve_date: this.itemsDataBooking.b_approve_date,

          b_revise_count: this.itemsDataBooking.b_revise_count,
          b_revise_last_update: this.itemsDataBooking.b_revise_last_update,
        };
        var res = await feathersClientUOA
          .service("booking")
          .patch(this.idbook, DataAdd);

        //SEQ LOG
        let properties = {
          b_id: parseInt(this.idbook),
          bs_id: this.itemsDataBooking.bs_id,
          f_no: this.flightno, o_id: this.customer.o_id,
          b_departure_date: this.date + " " + this.time,
          bt_id: this.bookingtype.bt_id,
          ap_id: this.departurefrom.ap_id,
          ap_departairport_name: this.departurefrom.ap_name
        };
        this.$log.Info('{action} by {username} ({role}) : b_id={b_id}, ap_departairport_name={ap_departairport_name}', { action: "EditBooking", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
        this.dialogSave = false;
        this.dialogConfirm = true;
      } catch (error) {
        //SEQ LOG
        this.$log.Error("EditBooking, Error : {error}", { error });
        console.log("EditBooking, Error : {error}" + error);
      }
    },

    async Updatetodb(Idbookinglegs, dataUpdate) {
      try {
        await feathersClientUOA.service("bookingleg").patch(Idbookinglegs, dataUpdate);
        //SEQ LOG
        let properties = {
          b_id: parseInt(this.idbook),
          bl_id: Idbookinglegs,
          bl_dest_airport_id: dataUpdate.bl_dest_airport_id,
          bl_activity_1: dataUpdate.bl_activity_1,
          bl_activity_2: dataUpdate.bl_activity_2,
          bl_activity_3: dataUpdate.bl_activity_3,
          bl_activity_4: dataUpdate.bl_activity_4
        };
        this.$log.Info('{action} by {username} ({role}) : b_id={b_id}, bl_id={bl_id}', { action: "EditLegInBooking", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("EditLegInBooking, Error : {error}", { error });
        console.log("EditLegInBooking, Error : {error}" + error);
      }
    },
    async DeleteBooking() {
      try {
        var DataEdit = {
          mb: this.userdata.FullName,
          md: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
          retire: 1,
        };
        await feathersClientUOA.service("booking").patch(this.idbook, DataEdit);

        //SEQ LOG
        let properties = {
          b_id: parseInt(this.idbook)
        };
        this.$log.Info('{action} by {username} ({role}) : b_id={b_id}', { action: "DeleteBooking", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });

        this.$router.push("/booking");
      } catch (error) {
        //SEQ LOG
        this.$log.Error("DeleteBooking, Error : {error}", { error });
        console.log("DeleteBooking, Error : {error}" + error);
      }
    },
    async closeDialog() {
      this.dialogEvent = false;
      this.dialogDelete = false;
      this.dialogConfirm = false;
      this.dialogSave = false;
      this.dialogSubmit = false;
    },
    async GotoListBooking() {
      this.closeDialog();
      this.$router.push("/booking");
    },
    async SaveCompleted() {
      this.closeDialog();
      await this.RenderUI();
    },
    async GotoCreateFlight() {
      this.$isLoading(true); // show loading screen

      (this.buttonCancelCreateFlight = true),
        (this.buttonSaveCreateFlight = true),
        //Save All
        await this.SaveEditToDB();

      //Get destonation in bookingleg (ap_destairport_id)
      var ap_destairport_id = "";
      var q = {};
      q.b_id = this.idbook;
      q.$eager = "[airport]";
      q.retire = "0";
      //น้อยไปมาก
      q.$sort = {
        bl_id: 1,
      };
      var resLeg = await feathersClientUOA
        .service("bookingleg")
        .find({ query: q });
      if (resLeg.total > 0) {
        var last = resLeg.data.length - 1;
        ap_destairport_id = resLeg.data[last].airport.ap_id;
      } else {
        ap_destairport_id = this.departurefrom.ap_id;
      }

      //CreateFlight
      //Get FH_Basic
      var q = {};
      q.o_id = this.customer.o_id;
      var res = await feathersClientUOA.service("customer").find({ query: q });
      let FH_Basic = res.data[0].FH_basis;

      if (this.customer.o_id == 4 && this.departurefrom.ap_id == 2) {
        FH_Basic = "Airtime";
      }

      var idflight = 0;
      try {
        var DataCreateFlight = {
          tm_id: 1,
          ac_id: null,
          fs_id: 1,
          ap_departairport_id: this.departurefrom.ap_id,
          ap_depart_date: this.date + " " + this.time,
          ap_destairport_id: this.departurefrom.ap_id,
          ap_dest_date: this.date + " " + this.time,
          f_flight_no: this.flightno,
          f_pilot_name: null,
          f_copilot_name: null,
          f_pilot_name: null,
          f_copilot_name: null,
          f_assing_aircraft_by: null,
          f_assing_aircraft_date: null,
          f_available_payload: null,
          f_revise_depart_date: null,
          f_revise_aircraf_id: null,
          f_actual_engine_start: null,
          f_code_delay: null,
          f_reason_delay: null,
          f_delay_cause: null,
          o_id: this.customer.o_id,
          b_id: this.idbook,
          ft_id: 1,
          f_type_of_flight: "Revenue",
          f_depart_weather_visibility: this.depart_visibility,
          f_depart_weather_oat: this.depart_oat,
          f_depart_weather_wind_spd: this.depart_wind_spd,
          f_depart_weather_qnh: this.depart_qnh,
          f_dest_weather_visibility: this.dest_visibility,
          f_dest_weather_oat: this.dest_oat,
          f_dest_weather_wind_spd: this.dest_wind_spd,
          f_dest_weather_qnh: this.dest_qnh,
          cb: this.userdata.FullName,
          cd: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
          f_assing_pilot_by: null,
          f_assing_pilot_date: null,
          //Add 20220324
          //FH_basis: "Airtime",
          //Edit 20220419
          FH_basis: FH_Basic,
        };
        var res = await feathersClientUOA
          .service("flight")
          .create(DataCreateFlight);
        idflight = res.f_id;
        this.thisFlight = idflight;
        this.dialogConfirmToCreateFlight = false;
        this.ButtonGoToFlight = true;

        //CreateFlightFromBooking
        //SEQ LOG
        let properties = {
          f_id: res.f_id, f_flight_no: this.flightno,
          fs_id: DataCreateFlight.fs_id,
          f_type_of_flight: DataCreateFlight.f_type_of_flight,
          tm_id: DataCreateFlight.tm_id,
          ap_departairport_id: DataCreateFlight.ap_departairport_id,
          ap_depart_date: DataCreateFlight.ap_depart_date,
          ap_destairport_id: DataCreateFlight.ap_destairport_id,
          ap_dest_date: DataCreateFlight.ap_dest_date,
          FH_basis: DataCreateFlight.FH_basis,
          b_id: parseInt(DataCreateFlight.b_id),
          o_id: DataCreateFlight.o_id,
        };
        this.$log.Info('{action} by {username} ({role}) : f_id={f_id}, b_id={b_id}', { action: "CreateFlightFromBooking", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("CreateFlightFromBooking, Error : {error}", { error });
        console.log("CreateFlightFromBooking, Error : {error}" + error);
      }

      //Create PassengerOutbound
      await this.AddPassengerBookingToPassengerFlightInAndOut(idflight, 1);
      //Create PassengerInbound
      await this.AddPassengerBookingToPassengerFlightInAndOut(idflight, 2);

      //Create Leg
      try {
        //Get Legs IN booking leg
        var legs = [];
        var q = {};
        q.b_id = this.idbook;
        q.$eager = "[airport]";
        q.retire = "0";
        q.$sort = {
          bl_id: 1,
        };

        var resLeg = await feathersClientUOA
          .service("bookingleg")
          .find({ query: q });
        if (resLeg.total > 0) {
          legs = resLeg.data;
          let loop_no = 0;
          legs.forEach(async (element) => {
            loop_no = loop_no + 1;           
            var DataAdd = {
              f_id: idflight,
              ap_id: element.airport.ap_id,
              ap_name: element.airport.ap_name,
              ap_color: element.airport.ap_color,
              l_activity1: element.bl_activity_1,
              l_activity2: element.bl_activity_2,
              l_activity3: element.bl_activity_3,
              l_activity4: element.bl_activity_4,
              l_activity5: element.bl_activity_5,
              //no data manage by flight off
              l_weather_visibility: element.l_weather_visibility,
              l_weather_oat: element.l_weather_oat,
              l_weather_wind_spd: element.l_weather_wind_spd,
              l_weather_qnh: element.l_weather_qnh,
              l_etd: element.l_etd,
              l_eta: element.l_eta,
              l_loop_no : loop_no,
              retire: 0,
            };
            await feathersClientUOA.service("leg").create(DataAdd);
          });
        }
      } catch (error) {
        console.log(error);
      }
      //Create Cargo (Flight off manage)

      //Update flightId to booking
      try {
        var DataEdit = {
          f_id: idflight,
        };
        await feathersClientUOA.service("booking").patch(this.idbook, DataEdit);
      } catch (error) {
        console.log(error);
      }
      this.dialogConfirm = true;

      //Create Delay Add 20220328
      var dataDelay = {
        f_id: idflight,
        d_id: 0,
        dc_id: null,
        fd_time: 0,
        fd_remark: "",
      };
      await feathersClientUOA.service("flightdelay").create(dataDelay);
      //Create Delay Add 20220328
      this.$isLoading(false); // hide loading screen


      this.$Notification.FlightCreateFromBooking(idflight);
    },

    async AddPassengerBookingToPassengerFlightInAndOut(
      flightId,
      passengerTypeId
    ) {
      var passenderBook = [];
      var q = {};
      q.pt_id = passengerTypeId;
      q.b_id = this.idbook;
      var res = await feathersClientUOA
        .service("bookingpassenger")
        .find({ query: q });
      passenderBook = res.data;
      passenderBook.forEach(async (passenger) => {
        var dataPassenger = {
          f_id: flightId,
          u_id: passenger.u_id,
          c_id: null,
          p_imageurl: passenger.p_imageurl,
          ap_departairport_id: passenger.ap_departairport_id,
          p_departure_date: null,
          ap_destairport_id: passenger.ap_destairport_id,
          p_destination_date: null,

          // p_body_weight: passenger.bp_body_weight,
          //p_body_weight: 0,
          p_body_weight_estimate: passenger.bp_body_weight,

          p_body_weight: null,
          p_seat_no: null,
          p_checkin: null,
          p_checkin_date: null,
          p_checkin_signature: null,
          p_checkin_by: null,
          p_firstname: null,
          p_lastname: null,
          p_rfid: null,
          as_id: null,
          p_brief_checkin: null,
          p_brief_checkin_time: null,
          pt_id: passengerTypeId,
          p_offload: null,
          ol_id: null,
          p_offload_remark: null,
          p_remark: passenger.bp_remark,
          p_inter_field: passenger.bp_inter_field,

          //p_baggage_weight: passenger.bp_baggage_weight,
          //p_baggage_weight: 0,
          p_baggage_weight_estimate: passenger.bp_baggage_weight,
        };
        var res = await feathersClientUOA
          .service("passenger")
          .create(dataPassenger);
      });
    },
    GotoFlight() {
      this.$router.push({
        name: "FlightDetail",
        params: { mode: "Edit", id: this.thisFlight },
      });
    },
    async SelectListPassengerReport() {
      this.ArrListPassenger = [];
      this.ArrPassLeg_Out = [];
      this.ArrPassLeg_In = [];

      try {
        var q = {};
        q.b_id = this.idbook;
        q.retire = "0";
        var res = await feathersClientUOA
          .service("viewbookingpassenger")
          .find({ query: q });
        this.ArrListPassenger = res.data;
      } catch (error) {
        console.log(
          "SelectListPassengerReport ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
          error
        );
      }

      var tempLegsName_Out = "";
      var tempLegsNameInter_Out = "";
      var tempLegsName_In = "";
      var tempLegsNameInter_In = "";
      var PassOutRaw = this.ArrListPassenger.filter((x) => x.pt_id == 1).filter(
        (x) => x.bp_inter_field != "1"
      );
      var PassInRaw = this.ArrListPassenger.filter((x) => x.pt_id == 2).filter(
        (x) => x.bp_inter_field != "1"
      );

      var PassOutRaw_INTERRIG = this.ArrListPassenger.filter(
        (x) => x.pt_id == 1
      ).filter((x) => x.bp_inter_field == "1");
      var PassInRaw_INTERRIG = this.ArrListPassenger.filter(
        (x) => x.pt_id == 2
      ).filter((x) => x.bp_inter_field == "1");
      let No_Out = 1;
      let No_In = 1;

      let Body_weight_out = 0;
      let Body_weight_in = 0;
      let Baggage_weight_out = 0;
      let Baggage_weight_in = 0;

      //ปิด
      // PassOutRaw = PassOutRaw.sort((a, b) => a.ap_destairport_name > b.ap_destairport_name ? 1 : b.ap_destairport_name > a.ap_destairport_name ? -1 : 0);

      //Start Outbound Passengers
      PassOutRaw.forEach((element) => {
        if (No_Out == 1) {
          this.ArrPassLeg_Out.push([
            { text: "No.", style: "tablefontsize" },
            { text: "Name-Surname", style: "tablefontsize" },
            { text: "Designation", style: "tablefontsize" },
            { text: "Company", style: "tablefontsize" },
            { text: "Passport Number", style: "tablefontsize" },
            { text: "Nationality", style: "tablefontsize" },
            { text: "Body Weight (KG)", style: "tablefontsize" },
            { text: "Luggage Weight (KG)", style: "tablefontsize" },
            { text: "Tel No.", style: "tablefontsize" },
            { text: "Department/Cost centre", style: "tablefontsize" },
            { text: "CPOC OSP Yes/ No /Waiver", style: "tablefontsize" },
            { text: "Survival Expiry date", style: "tablefontsize" },
            { text: "Medical Expiry date", style: "tablefontsize" },
          ]);
        }

        //Add
        let u_safety_training = format(
          new Date(element.u_safety_training),
          "dd-MMM-yy"
        );
        let u_medical_checkup = format(
          new Date(element.u_medical_checkup),
          "dd-MMM-yy"
        );
        if (element.u_waive_safety_training) {
          u_safety_training = u_safety_training + " (waive)";
        }
        if (element.u_waive_medical_checkup) {
          u_medical_checkup = u_medical_checkup + " (waive)";
        }

        if (tempLegsName_Out != element.ap_destairport_name) {
          this.ArrPassLeg_Out.push([
            { text: "", border: [true, false, false, false] },
            {
              text: "MOBILIZE FROM :",
              color: "#29F21C ",
              fontSize: 8,
              border: [false, false, false, false],
            },
            {
              text: element.ap_departairport_name,
              color: "#29F21C ",
              fontSize: 10,
              border: [false, false, false, false],
            },
            {
              text: "TO :",
              color: "#29F21C ",
              fontSize: 8,
              border: [false, false, false, false],
            },
            {
              text: element.ap_destairport_name,
              color: "#29F21C ",
              fontSize: 10,
              border: [false, false, false, false],
            },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, true, false] },
          ]);
          this.ArrPassLeg_Out.push([
            { text: No_Out },
            {
              text: element.u_firstname + " " + element.u_lastname,
              alignment: "left",
            },
            { text: element.u_jobposition },
            { text: element.c_name },
            { text: element.u_idcard_no },
            { text: element.u_national_id },
            { text: element.bp_body_weight },
            { text: element.bp_baggage_weight },
            { text: element.u_telephone },
            { text: element.u_department },
            { text: "YES" },
            // { text: format(new Date(element.u_safety_training), "dd-MMM-yy") },
            // { text: format(new Date(element.u_medical_checkup), "dd-MMM-yy") },
            { text: u_safety_training },
            { text: u_medical_checkup },
          ]);

          tempLegsName_Out = element.ap_destairport_name;
          Body_weight_out += element.bp_body_weight;
          Baggage_weight_out += element.bp_baggage_weight;
        } else {
          this.ArrPassLeg_Out.push([
            { text: No_Out },
            {
              text: element.u_firstname + " " + element.u_lastname,
              alignment: "left",
            },
            { text: element.u_jobposition },
            { text: element.c_name },
            { text: element.u_idcard_no },
            { text: element.u_national_id },
            { text: element.bp_body_weight },
            { text: element.bp_baggage_weight },
            { text: element.u_telephone },
            { text: element.u_department },
            { text: "YES" },
            // { text: format(new Date(element.u_safety_training), "dd-MMM-yy") },
            // { text: format(new Date(element.u_medical_checkup), "dd-MMM-yy") },
            { text: u_safety_training },
            { text: u_medical_checkup },
          ]);
          Body_weight_out += element.bp_body_weight;
          Baggage_weight_out += element.bp_baggage_weight;
        }

        No_Out++;
      });
      //End Outbound Passenger

      //Start Outbound Passenger (INTERRIG)
      PassOutRaw_INTERRIG.forEach((element) => {
        //Add
        let u_safety_training = format(
          new Date(element.u_safety_training),
          "dd-MMM-yy"
        );
        let u_medical_checkup = format(
          new Date(element.u_medical_checkup),
          "dd-MMM-yy"
        );
        if (element.u_waive_safety_training) {
          u_safety_training = u_safety_training + " (waive)";
        }
        if (element.u_waive_medical_checkup) {
          u_medical_checkup = u_medical_checkup + " (waive)";
        }

        if (tempLegsNameInter_Out != element.ap_destairport_name) {
          this.ArrPassLeg_Out.push([
            { text: "", border: [true, false, false, false] },
            {
              text: "INTER RIG FROM :",
              color: "#FF0000 ",
              fontSize: 8,
              border: [false, false, false, false],
            },
            {
              text: element.ap_departairport_name,
              color: "#FF0000",
              fontSize: 10,
              border: [false, false, false, false],
            },
            {
              text: "TO :",
              color: "#FF0000 ",
              fontSize: 8,
              border: [false, false, false, false],
            },
            {
              text: element.ap_destairport_name,
              color: "#FF0000 ",
              fontSize: 10,
              border: [false, false, false, false],
            },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, true, false] },
          ]);
          this.ArrPassLeg_Out.push([
            { text: No_Out },
            {
              text: element.u_firstname + " " + element.u_lastname,
              alignment: "left",
            },
            { text: element.u_jobposition },
            { text: element.c_name },
            { text: element.u_idcard_no },
            { text: element.u_national_id },
            { text: element.bp_body_weight },
            { text: element.bp_baggage_weight },
            { text: element.u_telephone },
            { text: element.u_department },
            { text: "YES" },
            // { text: format(new Date(element.u_safety_training), "dd-MMM-yy") },
            // { text: format(new Date(element.u_medical_checkup), "dd-MMM-yy") },
            { text: u_safety_training },
            { text: u_medical_checkup },
          ]);

          tempLegsNameInter_Out = element.ap_destairport_name;
          Body_weight_out += element.bp_body_weight;
          Baggage_weight_out += element.bp_baggage_weight;
        } else {
          this.ArrPassLeg_Out.push([
            { text: No_Out },
            {
              text: element.u_firstname + " " + element.u_lastname,
              alignment: "left",
            },
            { text: element.u_jobposition },
            { text: element.c_name },
            { text: element.u_idcard_no },
            { text: element.u_national_id },
            { text: element.bp_body_weight },
            { text: element.bp_baggage_weight },
            { text: element.u_telephone },
            { text: element.u_department },
            { text: "YES" },
            // { text: format(new Date(element.u_safety_training), "dd-MMM-yy") },
            // { text: format(new Date(element.u_medical_checkup), "dd-MMM-yy") },
            { text: u_safety_training },
            { text: u_medical_checkup },
          ]);
          Body_weight_out += element.bp_body_weight;
          Baggage_weight_out += element.bp_baggage_weight;
        }

        No_Out++;
      });
      //End Outbound Passenger (INTERRIG)

      this.ArrPassLeg_Out.push([
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        {
          text: Body_weight_out,
          fillColor: "#CBCACA",
          border: [true, true, true, true],
        },
        {
          text: Baggage_weight_out,
          fillColor: "#CBCACA",
          border: [true, true, true, true],
        },
        { text: "", border: [false, false, false, false] }, // { text: "=", border: [false, false, false, false] },
        {
          text: (Body_weight_out * 2.2).toFixed(0),
          fillColor: "#CBCACA",
          border: [true, true, true, true],
        },
        {
          text: (Baggage_weight_out * 2.2).toFixed(0),
          fillColor: "#CBCACA",
          border: [true, true, true, true],
        },
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
      ]);
      this.ArrPassLeg_Out.push([
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: "Total Weight", border: [false, false, false, false] },
        {
          text: (Body_weight_out + Baggage_weight_out).toFixed(0),
          fillColor: "#CBCACA",
          border: [true, true, true, true],
        },
        { text: "KGS", fillColor: "#CBCACA", border: [true, true, true, true] },
        { text: "", border: [false, false, false, false] }, // { text: "=", border: [false, false, false, false] },
        {
          text: (Body_weight_out * 2.2 + Baggage_weight_out * 2.2).toFixed(0),
          fillColor: "#CBCACA",
          border: [true, true, true, true],
        },
        { text: "LBS", fillColor: "#CBCACA", border: [true, true, true, true] },
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
      ]);

      //Start Inbound Passengers
      PassInRaw.forEach((element) => {
        if (No_In == 1) {
          this.ArrPassLeg_In.push([
            { text: "No.", style: "tablefontsize" },
            { text: "Name-Surname", style: "tablefontsize" },
            { text: "Designation", style: "tablefontsize" },
            { text: "Company", style: "tablefontsize" },
            { text: "Passport Number", style: "tablefontsize" },
            { text: "Nationality", style: "tablefontsize" },
            { text: "Body Weight (KG)", style: "tablefontsize" },
            { text: "Luggage Weight (KG)", style: "tablefontsize" },
            { text: "Tel No.", style: "tablefontsize" },
            { text: "Department/Cost centre", style: "tablefontsize" },
            { text: "CPOC OSP Yes/ No /Waiver", style: "tablefontsize" },
            { text: "Survival Expiry date", style: "tablefontsize" },
            { text: "Medical Expiry date", style: "tablefontsize" },
          ]);
        }

        //Add
        let u_safety_training = format(
          new Date(element.u_safety_training),
          "dd-MMM-yy"
        );
        let u_medical_checkup = format(
          new Date(element.u_medical_checkup),
          "dd-MMM-yy"
        );
        if (element.u_waive_safety_training) {
          u_safety_training = u_safety_training + " (waive)";
        }
        if (element.u_waive_medical_checkup) {
          u_medical_checkup = u_medical_checkup + " (waive)";
        }

        //if (tempLegsName_In != element.ap_destairport_name) {
        if (tempLegsName_In != element.ap_departairport_name) {
          this.ArrPassLeg_In.push([
            { text: "", border: [true, false, false, false] },
            {
              text: "MOBILIZE FROM :",
              color: "#29F21C ",
              fontSize: 6,
              border: [false, false, false, false],
            },
            {
              text: element.ap_departairport_name,
              color: "#29F21C ",
              fontSize: 8,
              border: [false, false, false, false],
            },
            {
              text: "TO :",
              color: "#29F21C ",
              fontSize: 6,
              border: [false, false, false, false],
            },
            {
              text: element.ap_destairport_name,
              color: "#29F21C ",
              fontSize: 8,
              border: [false, false, false, false],
            },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, true, false] },
          ]);
          this.ArrPassLeg_In.push([
            { text: No_In },
            {
              text: element.u_firstname + " " + element.u_lastname,
              alignment: "left",
            },
            { text: element.u_jobposition },
            { text: element.c_name },
            { text: element.u_idcard_no },
            { text: element.u_national_id },
            { text: element.bp_body_weight },
            { text: element.bp_baggage_weight },
            { text: element.u_telephone },
            { text: element.u_department },
            { text: "YES" },
            // { text: format(new Date(element.u_safety_training), "dd-MMM-yy") },
            // { text: format(new Date(element.u_medical_checkup), "dd-MMM-yy") },
            { text: u_safety_training },
            { text: u_medical_checkup },
          ]);

          tempLegsName_In = element.ap_departairport_name;
          Body_weight_in += element.bp_body_weight;
          Baggage_weight_in += element.bp_baggage_weight;

          // if
        } else {
          this.ArrPassLeg_In.push([
            { text: No_In },
            {
              text: element.u_firstname + " " + element.u_lastname,
              alignment: "left",
            },
            { text: element.u_jobposition },
            { text: element.c_name },
            { text: element.u_idcard_no },
            { text: element.u_national_id },
            { text: element.bp_body_weight },
            { text: element.bp_baggage_weight },
            { text: element.u_telephone },
            { text: element.u_department },
            { text: "YES" },
            // { text: format(new Date(element.u_safety_training), "dd-MMM-yy") },
            // { text: format(new Date(element.u_medical_checkup), "dd-MMM-yy") },
            { text: u_safety_training },
            { text: u_medical_checkup },
          ]);
          Body_weight_in += element.bp_body_weight;
          Baggage_weight_in += element.bp_baggage_weight;
        }

        No_In++;
      });
      //End Inbound Passengers

      //Start Inbound Passengers (INTERRIG)
      PassInRaw_INTERRIG.forEach((element) => {
        //Add
        let u_safety_training = format(
          new Date(element.u_safety_training),
          "dd-MMM-yy"
        );
        let u_medical_checkup = format(
          new Date(element.u_medical_checkup),
          "dd-MMM-yy"
        );
        if (element.u_waive_safety_training) {
          u_safety_training = u_safety_training + " (waive)";
        }
        if (element.u_waive_medical_checkup) {
          u_medical_checkup = u_medical_checkup + " (waive)";
        }

        if (tempLegsNameInter_In != element.ap_destairport_name) {
          this.ArrPassLeg_In.push([
            { text: "", border: [true, false, false, false] },
            {
              text: "INTER RIG FROM :",
              color: "#FF0000 ",
              fontSize: 8,
              border: [false, false, false, false],
            },
            {
              text: element.ap_departairport_name,
              color: "#FF0000",
              fontSize: 10,
              border: [false, false, false, false],
            },
            {
              text: "TO :",
              color: "#FF0000 ",
              fontSize: 8,
              border: [false, false, false, false],
            },
            {
              text: element.ap_destairport_name,
              color: "#FF0000 ",
              fontSize: 10,
              border: [false, false, false, false],
            },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, false, false] },
            { text: "", border: [false, false, true, false] },
          ]);
          this.ArrPassLeg_In.push([
            { text: No_In },
            {
              text: element.u_firstname + " " + element.u_lastname,
              alignment: "left",
            },
            { text: element.u_jobposition },
            { text: element.c_name },
            { text: element.u_idcard_no },
            { text: element.u_national_id },
            { text: element.bp_body_weight },
            { text: element.bp_baggage_weight },
            { text: element.u_telephone },
            { text: element.u_department },
            { text: "YES" },
            // { text: format(new Date(element.u_safety_training), "dd-MMM-yy") },
            // { text: format(new Date(element.u_medical_checkup), "dd-MMM-yy") },
            { text: u_safety_training },
            { text: u_medical_checkup },
          ]);

          tempLegsNameInter_In = element.ap_destairport_name;
          Body_weight_in += element.bp_body_weight;
          Baggage_weight_in += element.bp_baggage_weight;
        } else {
          this.ArrPassLeg_In.push([
            { text: No_In },
            {
              text: element.u_firstname + " " + element.u_lastname,
              alignment: "left",
            },
            { text: element.u_jobposition },
            { text: element.c_name },
            { text: element.u_idcard_no },
            { text: element.u_national_id },
            { text: element.bp_body_weight },
            { text: element.bp_baggage_weight },
            { text: element.u_telephone },
            { text: element.u_department },
            { text: "YES" },
            // { text: format(new Date(element.u_safety_training), "dd-MMM-yy") },
            // { text: format(new Date(element.u_medical_checkup), "dd-MMM-yy") },
            { text: u_safety_training },
            { text: u_medical_checkup },
          ]);
          Body_weight_in += element.bp_body_weight;
          Baggage_weight_in += element.bp_baggage_weight;
        }

        No_In++;
      });
      //End Inbound Passengers (INTERRIG)

      this.ArrPassLeg_In.push([
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        {
          text: Body_weight_in,
          fillColor: "#CBCACA",
          border: [true, true, true, true],
        },
        {
          text: Baggage_weight_in,
          fillColor: "#CBCACA",
          border: [true, true, true, true],
        },
        { text: "", border: [false, false, false, false] }, // { text: "=", border: [false, false, false, false] },
        {
          text: (Body_weight_in * 2.2).toFixed(0),
          fillColor: "#CBCACA",
          border: [true, true, true, true],
        },
        {
          text: (Baggage_weight_in * 2.2).toFixed(0),
          fillColor: "#CBCACA",
          border: [true, true, true, true],
        },
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
      ]);
      this.ArrPassLeg_In.push([
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: "Total Weight", border: [false, false, false, false] },
        {
          text: (Body_weight_in + Baggage_weight_in).toFixed(0),
          fillColor: "#CBCACA",
          border: [true, true, true, true],
        },
        { text: "KGS", fillColor: "#CBCACA", border: [true, true, true, true] },
        { text: "", border: [false, false, false, false] }, // { text: "=", border: [false, false, false, false] },
        {
          text: (Body_weight_in * 2.2 + Baggage_weight_in * 2.2).toFixed(0),
          fillColor: "#CBCACA",
          border: [true, true, true, true],
        },
        { text: "LBS", fillColor: "#CBCACA", border: [true, true, true, true] },
        { text: "", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
      ]);
    },
    async Submit() {
      this.dialogSubmit = false;
      this.itemsDataBooking.bs_id = 2;
      this.itemsDataBooking.b_revise_count = 0;
      this.itemsDataBooking.b_revise_last_update = new Date();
      this.itemsDataBooking.b_submit_date = format(new Date(), "yyyy-MMM-dd HH:mm:ss");
      await this.SaveEditToDB();
      //SEQ LOG
      let properties = {
        b_id: parseInt(this.idbook),
        bs_id: parseInt(this.itemsDataBooking.bs_id),
        b_submit_date: this.itemsDataBooking.b_submit_date
      };
      this.$log.Info('{action} by {username} ({role}) : b_id={b_id}, bs_id={bs_id}, b_submit_date={b_submit_date}', { action: "SubmitBooking", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      //Noti BookingSubmit
      let destinationString = this.departurefrom.ap_name + "=>";
      let count = this.listAddLegs.length;

      if (this.listAddLegs.length > 0) {
        for (let i = 0; i < count + 1; i++) {
          if (i < count) {
            destinationString += this.listAddLegs[i].legs.ap_name + "=>";
          }
        }
      }
      destinationString += this.departurefrom.ap_name;
      this.$Notification.BookingSubmit(this.idbook);
      this.RenderUI();
    },
    async Accept() {
      this.dialogAccept = false;
      this.itemsDataBooking.bs_id = 3;
      this.itemsDataBooking.b_accept_date = format(new Date(), "yyyy-MMM-dd HH:mm:ss");
      this.itemsDataBooking.b_approve = this.userdata.FullName;
      this.itemsDataBooking.b_approve_date = format(new Date(), "yyyy-MMM-dd HH:mm:ss");
      await this.SaveEditToDB();
      //SEQ LOG
      let properties = {
        b_id: parseInt(this.idbook),
        bs_id: parseInt(this.itemsDataBooking.bs_id),
        b_accept_date: this.itemsDataBooking.b_accept_date,
        b_approve: this.itemsDataBooking.b_approve,
        b_approve_date: this.itemsDataBooking.b_approve_date
      };
      this.$log.Info('{action} by {username} ({role}) : b_id={b_id}, bs_id={bs_id}, b_accept_date={b_accept_date}', { action: "AcceptBooking", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      //Noti BookingAccept
      let destinationString = this.departurefrom.ap_name + "=>";
      let count = this.listAddLegs.length;

      if (this.listAddLegs.length > 0) {
        for (let i = 0; i < count + 1; i++) {
          if (i < count) {
            destinationString += this.listAddLegs[i].legs.ap_name + "=>";
          } else {
            destinationString += "VTSH";
          }
        }
      }
      destinationString += this.departurefrom.ap_name;
      this.$Notification.BookingAccept(this.idbook);
    },
    async Booking_Report() {
      await this.SelectListPassengerReport();
      //Add
      var q = {};
      q.o_id = this.customer.o_id;
      var res = await feathersClientUOA.service("customer").find({ query: q });
      var imageCustomer = res.data[0].o_image;
      var getlogo = await this.$LogoService.GetLogoBaseurl64(
        this.customer.o_id
      );
      var logo = getlogo.logo;
      var w = getlogo.w;
      var h = getlogo.h;
      var margin = 0;

      var pdfMake = require("../../public/js/pdfmake");
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require("../../public/js/vfs_fonts");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }

      var docDefinition = {
        pageOrientation: "landscape",
        pageMargins: [40, 20, 20, 20],
        content: [
          {
            table: {
              widths: [10, 90, 50, 50, 60, 40, 40, 50, 50, 50, 50, 50, 50],
              body: [
                [
                  {
                    // text: "CARIGALI PTTEPI OPERATING COMPANY - HELICOPTER / VESSEL BOOKING FORM",
                    text: this.customer.o_name + " - HELICOPTER / BOOKING FORM",
                    style: "tableHeader",
                    colSpan: 13,
                    alignment: "center",
                  },
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                [
                  {
                    text: "TO",
                    colSpan: 6,
                    border: [true, false, false, false],
                  },
                  { text: "" },
                  { text: "" },
                  { text: "" },
                  { text: "" },
                  { text: "" },
                  {
                    text: "Request Ref :",
                    colSpan: 3,
                    border: [true, false, false, false],
                  },
                  { text: "" },
                  { text: "" },
                  {
                    text: "",
                    colSpan: 2,
                    border: [false, false, false, false],
                  },
                  { text: "" },
                  {
                    rowSpan: 3,
                    colSpan: 2,
                    border: [false, false, true, false],
                    alignment: "center",
                    margin: [0, -1, 0, 0],
                    width: w,
                    height: h,
                    image: logo,
                  },
                  { text: "" },
                ],
                [
                  {
                    text: "CC",
                    colSpan: 6,
                    border: [true, false, false, false],
                  },
                  { text: "" },
                  { text: "" },
                  { text: "" },
                  { text: "" },
                  { text: "" },
                  {
                    text: "Date Prepared :",
                    colSpan: 2,
                    border: [true, false, false, false],
                  },
                  { text: "" },
                  {
                    text: format(new Date(), "EEEE,dd MMMM,yyyy"),
                    alignment: "center",
                    colSpan: 2,
                    border: [false, false, false, false],
                  },
                  { text: "" },
                  {
                    // text: "No. of Pages :",
                    text: "",
                    colSpan: 2,
                    border: [false, false, false, false],
                  },

                  { text: "" },
                ],

                [
                  { text: "", border: [true, true, false, true] },
                  { text: "Travel Date", style: "tablefontsize" },
                  { text: "SKL Helicopter", style: "tablefontsize" },
                  { text: "KBR Helicopter", style: "tablefontsize" },
                  { text: "SKL Vessel", style: "tablefontsize" },
                  { text: "KSB Vessel", style: "tablefontsize" },
                  {
                    text: "Remarks :",
                    colSpan: 7,
                    border: [true, true, true, true],
                  },
                  { text: "" },
                  { text: "" },
                  { text: "" },
                  { text: "" },
                  { text: "" },
                  { text: "" },
                ],
                [
                  { text: "", border: [true, true, false, false] },
                  {
                    text: format(
                      new Date(this.itemsDataBooking.b_departure_date),
                      "EEEE,dd MMMM,yyyy"
                    ),
                    color: "red",
                    style: "tablefontsize",
                    border: [true, true, false, false],
                  },
                  {
                    text: this.flightno,
                    color: "red",
                    style: "tablefontsize",
                    border: [true, true, false, false],
                  },
                  { text: "", border: [true, true, false, false] },
                  { text: "", border: [true, true, false, false] },
                  { text: "", colSpan: 8, border: [true, true, true, false] },
                  { text: "" },
                  { text: "" },
                  { text: "" },
                  { text: "" },
                  { text: "" },
                  { text: "" },
                  { text: "" },
                ],
              ],
            },
          },
          {
            style: "tableExample",
            table: {
              widths: [10, 90, 50, 50, 60, 40, 40, 50, 50, 50, 50, 50, 50],
              body: this.ArrPassLeg_Out,
            },
          },

          {
            style: "tableExample1",
            table: {
              widths: [10, 90, 50, 50, 60, 40, 40, 50, 50, 50, 50, 50, 50],
              body: this.ArrPassLeg_In,
            },
            margin: [0, 10, 0, 5],
          },

          {
            table: {
              widths: [100, "*", "*"],
              body: [
                [
                  {
                    rowSpan: 2,
                    text: "Prepared By",
                    fontSize: 6,
                    alignment: "right",
                    border: [false, false, false, false],
                  },
                  {
                    // text: "Shore Co CPOC ",
                    text: "Shore Co " + this.customer.o_name,
                    fontSize: 6,
                    alignment: "center",
                    border: [false, false, false, false],
                  },
                  {
                    rowSpan: 2,
                    text: "",
                    border: [false, false, false, false],
                  },
                ],
                [
                  { text: "", border: [false, false, false, false] },
                  {
                    // text: "Helicopter Booking Procedures SSP ref: POD GEMP 00014 - Appendix 3, Rev 1 April 02",
                    text: "",
                    fontSize: 4,
                    alignment: "center",
                    border: [false, false, false, false],
                  },
                  { text: "", border: [false, false, false, false] },
                ],
              ],
            },
          },
        ],
        styles: {
          header: {
            fontSize: 12,
            bold: true,
            margin: [0, 0, 0, 10],
          },
          subheader: {
            fontSize: 8,
            bold: true,
            margin: [0, 10, 0, 5],
          },
          tableExample: {
            fontSize: 5,
            alignment: "center",
          },
          tableExample1: {
            fontSize: 5,
            alignment: "center",
            margin: [-10, -10, 10, 0],
          },
          tableHeader: {
            bold: true,
            fontSize: 6,
            color: "black",
            alignment: "center",
          },
          tablefontsize: {
            fontSize: 6,
            alignment: "center",
          },
        },
        defaultStyle: {
          // alignment: 'justify'
          fontSize: 6,
        },
      };
      pdfMake.createPdf(docDefinition).download("Booking_Report.pdf");
    },

    async GotoCreateTransportByBooking() {
      this.$isLoading(true); // show loading screen
      this.buttonCancelCreateTranaport = true;
      this.buttonSaveCreateTranaport = true;

      try {
        var tranSportId = 0;
        var DataAdd = {
          b_id: this.idbook,
          ts_date: this.date + " " + this.time,
          ref_id: this.ref_id,
          o_id: this.customer.o_id,
        };
        var res = await feathersClientUOA.service("transport").create(DataAdd);
        tranSportId = res.ts_id;
        this.thisTransport = tranSportId;
        await this.CreateTransportJobByBookingPassenger(tranSportId);
        //Update ts_id to booking
        try {
          var DataEdit = {
            ts_id: tranSportId,
          };
          await feathersClientUOA
            .service("booking")
            .patch(this.idbook, DataEdit);
        } catch (error) {
          console.log(error);
        }
        this.dialogConfirmToCreateTransport = false;
        this.dialogConfirm = true;
        //SEQ LOG
        let properties = {
          b_id: parseInt(this.idbook),
          ts_id: parseInt(res.ts_id),
          ts_date: DataAdd.ts_date
        };
        this.$log.Info('{action} by {username} ({role}) : b_id={b_id}, ts_id={ts_id}', { action: "CreateTransportFromBooking", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("CreateTransportFromBooking, Error : {error}", { error });
        console.log("CreateTransportFromBooking, Error : {error}" + error);
      }
      this.$isLoading(false); // show loading screen
    },
    GotoTransport() {
      try {
        this.$router.push({
          path: `/transportdetail/Edit/${this.thisTransport}/${this.idbook}`,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async CreateTransportJobByBookingPassenger(tranSportId) {
      var bookingPass = [];
      var q = {};
      q.b_id = this.idbook;
      q.retire = "0";

      var result = await feathersClientUOA
        .service("bookingpassenger")
        .find({ query: q });

      var resBookingPassOut = await this.ASCOrderbyBookingPassengerID(
        result.data.filter((x) => x.pt_id == 1)
      );

      var resBookingPassIn = await this.ASCOrderbyBookingPassengerID(
        result.data.filter((x) => x.pt_id == 2)
      );

      if (resBookingPassOut.length > 0) {
        try {
          resBookingPassOut.forEach(async (element) => {
            //Outbound
            if (element.pt_id == 1) {
              var DataAddOut = {
                tsl_picup_id: 1,
                tsl_drop_id: 3,
                tsj_picup_time: "00:00:00",
                tsj_drop_time: "00:00:00",
                u_id: element.u_id,
                ts_id: tranSportId,
                tsd_id: null,
                tsj_license_plate: null,
                tsj_remark: null,
                pt_id: 1,
                tsj_remark: element.bp_remark,
              };
              await this.CreateTransportJob(DataAddOut);
            }
          });
        } catch (error) {
          console.log(error);
        }
      }

      if (resBookingPassIn.length > 0) {
        try {
          resBookingPassIn.forEach(async (element) => {
            //Inbound
            if (element.pt_id == 2) {
              var DataAddIn = {
                tsl_picup_id: 3,
                tsl_drop_id: 1,
                tsj_picup_time: "00:00:00",
                tsj_drop_time: "00:00:00",
                u_id: element.u_id,
                ts_id: tranSportId,
                tsd_id: null,
                tsj_license_plate: null,
                tsj_remark: null,
                pt_id: 2,
                tsj_remark: element.bp_remark,
              };
              await this.CreateTransportJob(DataAddIn);
            }
          });
        } catch (error) {
          console.log(error);
        }
      }
    },

    async ASCOrderbyBookingPassengerID(list) {
      var result = list.sort(function (a, b) {
        let start = a.bo_id;
        let end = b.bo_id;
        return start - end;
      });

      return result;
    },

    async CreateTransportJob(DataAdd) {
      try {
        var res = await feathersClientUOA
          .service("transportjob")
          .create(DataAdd);
      } catch (error) {
        console.log(error);
      }
    },

    //Add
    async RevisePassenger() {
      try {
        var DataEdit = {
          b_revise_count: this.itemsDataBooking.b_revise_count + 1,
          b_revise_last_update: addHours(new Date(), 7),
        };

        var res = await feathersClientUOA
          .service("booking")
          .patch(this.idbook, DataEdit);

        this.$Notification.BookingRevise(this.idbook);
        this.RenderUI();

        //SEQ LOG
        let properties = {
          b_id: parseInt(this.idbook),
          b_revise_count: parseInt(DataEdit.b_revise_count),
          b_revise_last_update: DataEdit.b_revise_last_update
        };
        this.$log.Info('{action} by {username} ({role}) : b_id={b_id}, b_revise_count={b_revise_count}', { action: "ReviseBooking", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("ReviseBooking, Error : {error}", { error });
        console.log("ReviseBooking, Error : {error}" + error);
      }
    },

    AddPassengerSuccess() {
      this.DataPassengerFromDB();
    },
  },
};
</script>

<style scoped>
.sr-only {
  color: black !important;
  font-size: 42px !important;
}
</style>