w<template>
  <div>
    <div id="outPassenger" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Inbound Passengers</div>
      </div>
      <div class="box-S1">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-icon">
              <span class="icon I-passengers"></span>
            </div>
            <div class="b-text">
              <p>Passengers</p>
              <p class="T-size-20 SemiBold">{{ sumPassenger }} Passengers</p>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S1">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-icon">
              <span class="icon I-bodyweight"></span>
            </div>
            <div class="b-text">
              <p>Body Weight</p>
              <p class="T-size-20 SemiBold">
                {{ sumbodyweight.toFixed(2) }} Kg.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S1">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-icon">
              <span class="icon I-baggageweight"></span>
            </div>
            <div class="b-text">
              <p>Baggage Weight</p>
              <p class="T-size-20 SemiBold">
                {{ sumbaggageweight.toFixed(2) }} Kg.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S1">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-icon">
              <span class="icon I-totalweight"></span>
            </div>
            <div class="b-text">
              <p>Total Weight</p>
              <p class="T-size-20 SemiBold">{{ sumtotal.toFixed(2) }} Kg.</p>
            </div>
          </div>
        </div>
      </div>

      <!-- showAsGrid GRID -->
      <div class="box-S4" v-if="showAsGrid">
        <div class="box-S4">
          <v-expansion-panels
            accordion
            class="b-accod"
            v-model="panel"
            multiple
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="panal-title T-size-20">Passenger List</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="wrap-passenger">
                  <div
                    class="box-S1-3"
                    v-for="(item, index) in listPassenger"
                    :key="item.IdbookingPass"
                  >
                    <div
                      class="B-passenger"
                      :class="item.retire"
                      v-show="
                        item.MedicalCheckupDayLeft > '90' &&
                        item.SafetyTrainingDayLeft > '90'
                      "
                    >
                      <!-- เพิ่ม Class update / delete เมื่อมีการส่งค่าว่าโดนแก้ไข หรือ ลบ -->
                      <div>
                        <div class="b-person">
                          <div class="b-display">
                            <div
                              class="B-display person1"
                              v-if="
                                item.u_imageurl == undefined ||
                                item.u_imageurl == null
                              "
                            ></div>
                            <div
                              class="B-display person1"
                              v-else
                              :style="{
                                backgroundImage:
                                  'url(' + item.u_imageurl + ') !important',
                              }"
                            ></div>
                            <!-- เพิ่ม Class warning1 / warning2 เมื่อมี warning -->
                          </div>
                          <div class="b-name-company">
                            <p class="t-name T-size-14">{{ item.FullName }}</p>
                            <div class="t-company T-size-12">
                              <!-- Company : {{ item.c_name }} -->
                              Customer : {{ customer }}
                            </div>
                          </div>
                          <div class="b-action">
                            <div class="b-btn">
                              <v-btn
                                fab
                                class="btn-edit"
                                @click="EditItem(item, index)"
                                :disabled="
                                  readonly_eng ||
                                  readonly_pilot ||
                                  readonly_flight || readonly_super_flight
                                "
                              >
                                <span class="I-edit"></span>
                              </v-btn>
                              <v-btn
                                fab
                                class="primary btn-delete"
                                @click="DeleteItem(item)"
                                :disabled="
                                  readonly_eng ||
                                  readonly_pilot ||
                                  readonly_flight || readonly_super_flight
                                "
                              >
                                <span class="I-bin"></span>
                              </v-btn>
                            </div>
                          </div>
                        </div>
                        <div class="b-detail">
                          <div class="t-title">Destiantion</div>
                          <div class="box-S4 flex-between-center t-detail">
                            <div class="bL">
                              <span class="T-size-12">Departure From</span>
                              <p class="T-size-16">{{ item.departurefrom }}</p>
                            </div>
                            <div
                              class="bC"
                              v-if="item.checkboxinterfield == true"
                            >
                              <span class="T-size-12">Inter Field</span>
                              <i class="I-check"></i>
                            </div>
                            <div class="bR">
                              <span class="T-size-12">Destination</span>
                              <p class="T-size-16">{{ item.destination }}</p>
                            </div>
                          </div>
                        </div>
                        <div class="b-detail">
                          <div class="t-title">Weight</div>
                          <div class="box-S4 flex-between-center t-detail">
                            <div class="bL">
                              <span class="T-size-12">Body Weight</span>
                              <p class="T-size-16">{{ item.bodyweight }} Kg.</p>
                            </div>
                            <div class="bR">
                              <span class="T-size-12">Baggage Weight</span>
                              <p class="T-size-16">
                                {{ item.baggageweight }} Kg.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="b-recover">
                        <v-btn
                          class="theme-btn-even btn-recover"
                          @click="Recover(item, index)"
                          :disabled="
                            readonly_eng || readonly_pilot || readonly_flight || readonly_super_flight
                          "
                        >
                          <span class="T-size-18">Recover</span>
                        </v-btn>
                      </div>
                    </div>

                    <div
                      class="B-passenger"
                      :class="item.retire"
                      v-show="
                        item.MedicalCheckupDayLeft <= '90' ||
                        item.SafetyTrainingDayLeft <= '90'
                      "
                    >
                      <!-- Add show only CPOC -->
                      <div
                        class="B-passenger"
                        v-bind:class="{ expired: customer_id === 2 }"
                      >
                        <!-- เพิ่ม Class update / delete เมื่อมีการส่งค่าว่าโดนแก้ไข หรือ ลบ -->
                        <div>
                          <div class="b-person">
                            <div class="b-display">
                              <div
                                class="B-display person1"
                                v-if="
                                  item.u_imageurl == undefined ||
                                  item.u_imageurl == null
                                "
                              ></div>
                              <div
                                class="B-display person1"
                                v-else
                                :style="{
                                  backgroundImage:
                                    'url(' + item.u_imageurl + ') !important',
                                }"
                              ></div>
                              <!-- เพิ่ม Class warning1 / warning2 เมื่อมี warning -->
                            </div>
                            <div class="b-name-company">
                              <p class="t-name T-size-14">
                                {{ item.FullName }}
                              </p>
                              <!-- Add -->
                              <div
                                class="red--text"
                                v-show="customer_id === 2"
                              >
                                <v-icon
                                  class="subheading T-size-18"
                                  v-show="
                                    $CheckWarning.wranningSafetyTraining(
                                      item.SafetyTrainingDayLeft
                                    )
                                  "
                                  color="red"
                                  >mdi-clock-alert</v-icon
                                >
                                <span class="T-size-12">
                                  &nbsp;{{
                                    $CheckWarning.wranningSafetyTraining(
                                      item.SafetyTrainingDayLeft
                                    )
                                  }}</span
                                >
                              </div>
                              <div
                                class="red--text"
                                v-show="customer_id === 2"
                              >
                                <v-icon
                                  class="subheading T-size-18"
                                  v-show="
                                    $CheckWarning.wranningMedicalCheckup(
                                      item.MedicalCheckupDayLeft
                                    )
                                  "
                                  color="red"
                                  >mdi-clock-alert</v-icon
                                >
                                <span class="T-size-12">
                                  &nbsp;{{
                                    $CheckWarning.wranningMedicalCheckup(
                                      item.MedicalCheckupDayLeft
                                    )
                                  }}</span
                                >
                              </div>
                              <div class="t-company T-size-12">
                                <!-- Company : {{ item.c_name }} -->
                                Customer : {{ customer }}
                              </div>
                            </div>
                            <div class="b-action">
                              <div class="b-btn">
                                <v-btn
                                  fab
                                  class="btn-edit"
                                  @click="EditItem(item, index)"
                                  :disabled="
                                    readonly_eng ||
                                    readonly_pilot ||
                                    readonly_flight || readonly_super_flight
                                  "
                                >
                                  <span class="I-edit"></span>
                                </v-btn>
                                <v-btn
                                  fab
                                  class="primary btn-delete"
                                  @click="DeleteItem(item)"
                                  :disabled="
                                    readonly_eng ||
                                    readonly_pilot ||
                                    readonly_flight || readonly_super_flight
                                  "
                                >
                                  <span class="I-bin"></span>
                                </v-btn>
                              </div>
                            </div>
                          </div>
                          <div class="b-detail">
                            <div class="t-title">Destiantion</div>
                            <div class="box-S4 flex-between-center t-detail">
                              <div class="bL">
                                <span class="T-size-12">Departure From</span>
                                <p class="T-size-16">
                                  {{ item.departurefrom }}
                                </p>
                              </div>
                              <div
                                class="bC"
                                v-if="item.checkboxinterfield == true"
                              >
                                <span class="T-size-12">Inter Field</span>
                                <i class="I-check"></i>
                              </div>
                              <div class="bR">
                                <span class="T-size-12">Destination</span>
                                <p class="T-size-16">{{ item.destination }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="b-detail">
                            <div class="t-title">Weight</div>
                            <div class="box-S4 flex-between-center t-detail">
                              <div class="bL">
                                <span class="T-size-12">Body Weight</span>
                                <p class="T-size-16">
                                  {{ item.bodyweight }} Kg.
                                </p>
                              </div>
                              <div class="bR">
                                <span class="T-size-12">Baggage Weight</span>
                                <p class="T-size-16">
                                  {{ item.baggageweight }} Kg.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="b-recover">
                        <v-btn
                          class="theme-btn-even btn-recover"
                          @click="Recover(item, index)"
                          :disabled="
                            readonly_eng || readonly_pilot || readonly_flight || readonly_super_flight
                          "
                        >
                          <span class="T-size-18">Recover</span>
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <div v-show="listPassenger.length == 0">
                    <div class="b-nodata">
                      <div class="t-nodata">Please Select Passengers</div>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>

      <!-- showAsGrid ROW -->
      <div class="box-S4" v-if="!showAsGrid">
        <v-expansion-panels accordion class="b-accod" v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="panal-title T-size-20">Passenger List</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                class="box-4"
                v-for="(item, index) in listPassenger"
                :key="item.IdbookingPass"
              >
                <div
                  v-show="
                    item.MedicalCheckupDayLeft > '90' &&
                    item.SafetyTrainingDayLeft > '90'
                  "
                >
                  <div class="B-passenger TF">
                    <div class="b-person">
                      <div class="box-S1 fix-header">
                        <div
                          v-if="
                            item.u_imageurl == undefined ||
                            item.u_imageurl == null
                          "
                        ></div>
                        <div
                          class="B-display person1"
                          v-else
                          :style="{
                            backgroundImage:
                              'url(' + item.u_imageurl + ') !important',
                          }"
                        ></div>
                      </div>

                      <div class="box-S2">
                        <div class="b-name-company">
                          <p class="t-name T-size-14">{{ item.FullName }}</p>
                          <div class="t-company T-size-12">
                            <span v-if="customer != undefined">{{
                              customer
                            }}</span
                            >&nbsp;
                            <span v-if="item.c_name != undefined"
                              >[{{ item.c_name }}]</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="box-S1 flex-between-center t-detail">
                        <div class="bC">
                          <span class="T-size-12">Departure From</span>
                          <p class="T-size-16">{{ item.departurefrom }}</p>
                        </div>
                      </div>

                      <div class="box-S1 flex-between-center t-detail">
                        <div class="bC" v-if="item.checkboxinterfield == true">
                          <span class="T-size-12">Inter Field</span>
                          <i class="I-check"></i>
                        </div>
                      </div>

                      <div class="box-S1 flex-between-center t-detail">
                        <div class="bC">
                          <span class="T-size-12">Destination</span>
                          <p class="T-size-16">{{ item.destination }}</p>
                        </div>
                      </div>

                      <div class="box-S1 flex-between-center t-detail">
                        <div class="bC">
                          <span class="T-size-12">Body Weight</span>
                          <p class="T-size-16">{{ item.bodyweight }} Kg.</p>
                        </div>
                      </div>

                      <div class="box-S1 flex-between-center t-detail">
                        <div class="bC">
                          <span class="T-size-12">Baggage Weight</span>
                          <p class="T-size-16">{{ item.baggageweight }} Kg.</p>
                        </div>
                      </div>

                      <div class="b-action">
                        <div class="b-btn">
                          <v-btn
                            fab
                            class="btn-edit"
                            @click="EditItem(item, index)"
                            :disabled="
                              readonly_eng || readonly_pilot || readonly_flight || readonly_super_flight
                            "
                          >
                            <span class="I-edit"></span>
                          </v-btn>
                        </div>
                        <div class="b-btn">
                          <v-btn
                            fab
                            class="primary btn-delete"
                            @click="DeleteItem(item)"
                            :disabled="
                              readonly_eng || readonly_pilot || readonly_flight || readonly_super_flight
                            "
                          >
                            <span class="I-bin"></span>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-show="
                    item.MedicalCheckupDayLeft <= '90' ||
                    item.SafetyTrainingDayLeft <= '90'
                  "
                >
                  <div class="B-passenger TF">
                    <div class="b-person">
                      <div class="box-S1 fix-header">
                        <div
                          v-if="
                            item.u_imageurl == undefined ||
                            item.u_imageurl == null
                          "
                        ></div>
                        <div
                          class="B-display person1"
                          v-else
                          :style="{
                            backgroundImage:
                              'url(' + item.u_imageurl + ') !important',
                          }"
                        ></div>
                      </div>

                      <div class="box-S2">
                        <div class="b-name-company">
                          <p class="t-name T-size-14">{{ item.FullName }}</p>
                          <!-- Add show only CPOC -->
                          <div class="red--text" v-show="customer_id === 2">
                            <v-icon
                              class="subheading T-size-18"
                              v-show="
                                $CheckWarning.wranningSafetyTraining(
                                  item.SafetyTrainingDayLeft
                                )
                              "
                              color="red"
                              >mdi-clock-alert</v-icon
                            >
                            <span class="T-size-12">
                              &nbsp;{{
                                $CheckWarning.wranningSafetyTraining(
                                  item.SafetyTrainingDayLeft
                                )
                              }}</span
                            >
                          </div>

                          <div class="red--text" v-show="customer_id === 2">
                            <v-icon
                              class="subheading T-size-18"
                              v-show="
                                $CheckWarning.wranningMedicalCheckup(
                                  item.MedicalCheckupDayLeft
                                )
                              "
                              color="red"
                              >mdi-clock-alert</v-icon
                            >
                            <span class="T-size-12">
                              &nbsp;{{
                                $CheckWarning.wranningMedicalCheckup(
                                  item.MedicalCheckupDayLeft
                                )
                              }}</span
                            >
                          </div>
                          <!-- Add -->
                          <div class="t-company T-size-12">
                            <span v-if="customer != undefined">{{
                              customer
                            }}</span
                            >&nbsp;
                            <span v-if="item.c_name != undefined"
                              >[{{ item.c_name }}]</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="box-S1 flex-between-center t-detail">
                        <div class="bC">
                          <span class="T-size-12">Departure From</span>
                          <p class="T-size-16">{{ item.departurefrom }}</p>
                        </div>
                      </div>
                      <div class="box-S1 flex-between-center t-detail">
                        <div class="bC" v-if="item.checkboxinterfield == true">
                          <span class="T-size-12">Inter Field</span>
                          <i class="I-check"></i>
                        </div>
                      </div>
                      <div class="box-S1 flex-between-center t-detail">
                        <div class="bC">
                          <span class="T-size-12">Destination</span>
                          <p class="T-size-16">{{ item.destination }}</p>
                        </div>
                      </div>
                      <div class="box-S1 flex-between-center t-detail">
                        <div class="bC">
                          <span class="T-size-12">Body Weight</span>
                          <p class="T-size-16">{{ item.bodyweight }} Kg.</p>
                        </div>
                      </div>
                      <div class="box-S1 flex-between-center t-detail">
                        <div class="bC">
                          <span class="T-size-12">Baggage Weight</span>
                          <p class="T-size-16">{{ item.baggageweight }} Kg.</p>
                        </div>
                      </div>

                      <div class="b-action">
                        <div class="b-btn">
                          <v-btn
                            fab
                            class="btn-edit"
                            @click="EditItem(item, index)"
                            :disabled="
                              readonly_eng || readonly_pilot || readonly_flight || readonly_super_flight
                            "
                          >
                            <span class="I-edit"></span>
                          </v-btn>
                        </div>
                        <div class="b-btn">
                          <v-btn
                            fab
                            class="primary btn-delete"
                            @click="DeleteItem(item)"
                            :disabled="
                              readonly_eng || readonly_pilot || readonly_flight || readonly_super_flight
                            "
                          >
                            <span class="I-bin"></span>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div class="box-S1-3">
        <v-btn
          class="theme-btn-even MGB10"
          @click="AddPassenger()"
          v-show="!(readonly_eng || readonly_pilot || readonly_flight || readonly_super_flight)"
        >
          <span class="I-create"></span>
          <span>Add</span>
        </v-btn>
      </div>
      <!-- showAsGrid button -->
      <div class="box-S1">
        <v-switch v-model="showAsGrid" :label="`GRID`"> </v-switch>
      </div>
      <div class="line"></div>
    </div>
    <v-dialog v-model="dialogEvent" persistent max-width="400">
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <div class="B-dialogform step">
            <div class="t-namepage">{{ formTitle }} Passenger</div>
            <div class="b-form">
              <v-stepper v-model="stepdialog">
                <v-stepper-header>
                  <v-stepper-step
                    :complete="stepdialog > 1"
                    step="1"
                  ></v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step
                    :complete="stepdialog > 2"
                    step="2"
                  ></v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="3"></v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <div class="b-contentstep">
                      <div
                        class="box-btn-select"
                        v-show="arraychoospassenger[0].FullName == ''"
                      >
                        <v-btn
                          class="theme-btn-even btn-select"
                          @click="OpenDialogSelectPassenger()"
                        >
                          <span class="I-create"></span>
                          <span>Select Passenger</span>
                        </v-btn>
                      </div>
                      <div class="B-passenger TF">
                        <div v-show="arraychoospassenger[0].FullName != ''">
                          <div class="b-person">
                            <div class="b-display">
                              <div
                                class="B-display person1"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    arraychoospassenger[0].u_imageurl +
                                    ') !important',
                                }"
                              ></div>
                            </div>
                            <div class="b-name-company">
                              <p class="t-name">
                                {{ arraychoospassenger[0].FullName }}
                              </p>
                              <div class="t-company T-size-12">
                                <!-- Company : {{ arraychoospassenger[0].c_name }} -->
                                Customer : {{ customer }}
                              </div>
                            </div>
                          </div>
                          <div class="b-detail">
                            <div class="t-title">Remark</div>
                            <div class="box-S4 flex-between-center t-detail">
                              <v-text-field
                                label="Remark"
                                v-model="remark"
                              ></v-text-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="box-btn">
                      <v-btn
                        class="theme-btn-even btn-cancel Bsize100"
                        text
                        @click="dialogEvent = false"
                      >
                        <span class="T-size-18">cancel</span>
                      </v-btn>
                      <!-- <v-btn
                        class="theme-btn-even btn-ok Bsize100"
                        @click="stepdialog = 2"
                      >
                        <span class="T-size-18">next</span>
                      </v-btn> -->
                      <!-- Edit -->
                      <v-btn
                        class="theme-btn-even btn-ok Bsize100"
                        @click="GotoStepDialog2()"
                      >
                        <span class="T-size-18">next</span>
                      </v-btn>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <div class="b-contentstep">
                      <div class="B-passenger TF">
                        <div class="b-detail">
                          <div class="t-title">Destination</div>
                          <div class="box-S4 flex-between-center">
                            <div class="box-S2 t-detail">
                              <v-select
                                class="hideMessage"
                                v-model="departurefrom"
                                :items="itemsListAirport"
                                label="Departure From"
                                item-text="ap_name"
                                item-value="ap_id"
                                return-object
                              ></v-select>
                            </div>
                            <div class="box-S2 t-detail">
                              <v-select
                                class="hideMessage"
                                v-model="destination"
                                :items="itemsListAirport"
                                label="Destination"
                                item-text="ap_name"
                                item-value="ap_id"
                                return-object
                              ></v-select>
                            </div>
                          </div>
                          <div class="b-checkbox">
                            <v-checkbox
                              v-model="checkboxinterfield"
                              label="Inter Field"
                            ></v-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="box-btn">
                      <v-btn
                        class="theme-btn-even btn-cancel Bsize100"
                        text
                        @click="stepdialog = 1"
                      >
                        <span class="T-size-18">cancel</span>
                      </v-btn>
                      <v-btn
                        class="theme-btn-even btn-ok Bsize100"
                        @click="stepdialog = 3"
                      >
                        <span class="T-size-18">next</span>
                      </v-btn>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <div class="b-contentstep">
                      <div class="B-passenger TF">
                        <div class="b-detail">
                          <div class="t-title">Weight</div>
                          <div class="box-S4 flex-between-center">
                            <div class="box-S2 t-detail">
                              <v-text-field
                                label="Body Weight"
                                v-model="bodyweight"
                              ></v-text-field>
                            </div>
                            <div class="box-S2 t-detail">
                              <v-text-field
                                label="Baggage Weight"
                                v-model="baggageweight"
                              ></v-text-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="box-btn">
                      <v-btn
                        class="theme-btn-even btn-cancel Bsize100"
                        text
                        @click="stepdialog = 2"
                      >
                        <span class="T-size-18">cancel</span>
                      </v-btn>
                      <v-btn
                        class="theme-btn-even btn-save Bsize100"
                        text
                        @click="SaveToArrayPassenger()"
                      >
                        <span class="T-size-18">Finish</span>
                      </v-btn>
                    </div>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <!-- Select Passenger -->
    <v-dialog v-model="dialogSelectPassenger" persistent max-width="800" fullscreen>
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <div class="box-S4 flex-between-center">
            <div class="N-Page T-size-36 TF">Select Passenger</div>
            <v-btn
              class="theme-btn-even"
              @click="dialogSelectPassenger = false"
            >
              <span>Close</span>
            </v-btn>
          </div>
          <div class="box-S4">
            <v-text-field
              v-model="search"
              class="hideMessage"
              label="Search by firstname"
              v-on:keyup.enter="SearchPassenger()"
            ></v-text-field>
            <div class="b-action">
              <div class="b-btn">
                <v-btn @click="SearchPassenger()">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div class="box-S4 noPadding flex-between-center">
            <!-- <v-data-table :headers="headers" :search="search" :items="userItem" :items-per-page="5"> -->
            <v-data-table
              class="y-scroll"
              :headers="headers"
              :items="userItem"
              :items-per-page="5"
            >
              <template v-slot:item.action="{ item }">
                <div class="b-action">
                  <div class="b-btn">
                    <v-btn fab class="btn-edit" @click="ChoosePassenger(item)"
                      ><span class="I-add2"></span
                    ></v-btn>
                  </div>
                </div>
              </template>
              <template v-slot:item.u_imageurl="{ item }">
                <img class="B-display In-table" :src="item.u_imageurl" />
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import feathersClientUOA from "../../plugins/feathers-client-uoa";
export default {
  data: () => ({
    showAsGrid: false,
    readonly_super_flight: false,
    readonly_flight: false,
    readonly_pilot: false,
    readonly_eng: false,
    readonly_shore: false,

    userdata: "",
    modeEvent: "",
    dialogEvent: false,
    dialogSelectPassenger: false,
    stepdialog: 1,
    headers: [
      { value: "u_imageurl", text: "", sortable: false },
      // { value: "u_staffcode", text: "Staff Code", sortable: false },
      { value: "u_firstname", text: "First name", sortable: true },
      { value: "u_lastname", text: "Last name", sortable: true },
      // { value: "u_username", text: "Username", sortable: false },
      { value: "r_name", text: "Position", sortable: false },
      { value: "o_name", text: "Customer", sortable: false },
      { value: "action", text: "", sortable: false },
    ],
    search: "",
    itemsListAirport: [],
    itemsDataPassenger: [],
    userItem: [],
    // input Model
    remark: "",

    departurefrom: "",
    departurefromname: "",

    destination: "",
    destinationname: "",

    checkboxinterfield: false,
    bodyweight: "",
    baggageweight: 0,
    listPassenger: [],
    arraychoospassenger: [
      { u_id: "", FullName: "", c_name: "", u_imageurl: "" },
    ],

    //Add
    sumPassenger: 0,

    sumbodyweight: 0,
    sumbaggageweight: 0,
    sumtotal: 0,
    chooseItemPassengerIndex: null,
    chooseItemPassenger: null,
    IdbookingPass: null,
    idbook: null,
    panel: [0],
    retire: "",

    //Add
    safetyTrainingDayLeft: 0,
    medicalCheckupDayLeft: 0,
  }),
  props: ["customer", "bookingId","customer_id"],
  components: {},
  async mounted() {
    // this.userdata = JSON.parse(localStorage.getItem("user"));
    //Edit
    this.userdata = this.$CurrentUser.GetCurrentUser();
    this.renderUI();
    this.SelectListAirport();
    this.enable_disable();
  },
  computed: {
    formTitle() {
      return this.modeEvent === "create" ? "Add" : "Edit";
    },
  },
  methods: {
    //Add
    OpenDialogSelectPassenger() {
      this.search = "";
      this.userItem = [];
      this.dialogSelectPassenger = true;
    },
    async enable_disable() {
      if (this.userdata.r_id == 4) {
        this.readonly_flight = true;
      } else if (this.userdata.r_id == 9) {
        this.readonly_eng = true;
      } else if (this.userdata.r_id == 12) {
        this.readonly_pilot = true;
      } else if (this.userdata.r_id == 2) {
        this.readonly_shore = true;
      } else if (this.userdata.r_id == 21) {
        this.readonly_super_flight = true;
      }
    },

    async SelectListAirport() {
      try {
        var q = {};
        q.retire = "0";
        var res = await feathersClientUOA.service("airports").find({ query: q });
        this.itemsListAirport = res.data;
      } catch (error) {
        console.log("SelectListAirport ไม่สามารถขอข้อมูลจาก server ได้ /nError : " + error);
      }
    },
    async renderUI() {
      try {
        const { user } = await feathersClientUOA.get("authentication");
        //User
        // this.search = "";
        // const q = {};
        // //ยกเว้น flight เห็นหมดทุกคน
        // if (this.userdata.r_id == 2) {
        //   q.o_id = this.userdata.o_id;
        // }
        // q.r_id = 3;

        // let res = await feathersClientUOA
        //   .service("viewuser")
        //   .find({ query: q });
        // this.userItem = res.data;
      } catch (err) {

      }
    },
    async AddPassenger() {
      this.modeEvent = "create";
      this.dialogEvent = true;
      this.stepdialog = 1;
      this.arraychoospassenger = [
        {
          u_id: "",
          FullName: "",
          c_name: "",
          u_estimatedweight: "",
          u_imageurl: "",
        },
      ];
      this.remark = "";

      // this.departurefrom = "";
      // this.destination = "";
      //Edit set default airport
      this.departurefrom = this.itemsListAirport[0];
      this.destination = this.itemsListAirport[0];

      this.checkboxinterfield = false;
      this.bodyweight = "";
      this.baggageweight = null;
    },
    async EditItem(itemPass, index) {
      this.modeEvent = "edit";
      this.dialogEvent = true;
      this.stepdialog = 1;
      this.arraychoospassenger = [
        {
          u_id: itemPass.u_id,
          FullName: itemPass.FullName,
          c_name: itemPass.c_name,
          u_imageurl: itemPass.u_imageurl,
        },
      ];
      this.remark = itemPass.remark;

      //this.departurefrom = itemPass.ap_departairport_id;
      //this.departurefrom.ap_name = this.departurefrom.ap_name;
      //Edit
      this.departurefrom = itemPass.ap_departairport_id;
      this.departurefromname = itemPass.departurefrom;

      //this.destination = itemPass.ap_destairport_id;
      //this.destination.ap_name;
      //Edit
      this.destination = itemPass.ap_destairport_id;
      this.destinationname = itemPass.destination;

      this.checkboxinterfield = itemPass.checkboxinterfield;
      this.bodyweight = itemPass.bodyweight;
      this.baggageweight = itemPass.baggageweight;
      this.chooseItemPassengerIndex = index;

      //Add
      this.safetyTrainingDayLeft = itemPass.SafetyTrainingDayLeft;
      this.medicalCheckupDayLeft = itemPass.MedicalCheckupDayLeft;
    },
    async ChoosePassenger(dataUser) {
      this.dialogSelectPassenger = false;
      this.arraychoospassenger = [];
      this.arraychoospassenger.push(dataUser);
      this.bodyweight = dataUser.u_estimatedweight;
    },
    async SaveToArrayPassenger() {
      this.dialogEvent = false;
      if (this.modeEvent == "create") {
        //Add Save to DB
        var DataAdd = {
          u_id: this.arraychoospassenger[0].u_id,
          b_id: this.bookingId,
          ap_departairport_id: this.departurefrom.ap_id,
          ap_destairport_id: this.destination.ap_id,
          pt_id: 2,
          bp_body_weight: this.bodyweight,
          bp_baggage_weight: this.baggageweight,
          bp_remark: this.remark,
          bp_inter_field: this.checkboxinterfield,
        };
        await this.CreateBookingPassenger(DataAdd);
        //this.DataPassengerFromDB(this.bookingId);
      } else {
        //Add Edit to DB
        let departurefromId = null;
        if (this.departurefrom.ap_id) {
          departurefromId = this.departurefrom.ap_id;
        } else {
          departurefromId = this.departurefrom;
        }

        let destinationId = null;
        if (this.destination.ap_id) {
          destinationId = this.destination.ap_id;
        } else {
          destinationId = this.destination;
        }

        let dataUpdate = {
          ap_departairport_id: departurefromId,
          ap_destairport_id: destinationId,
          bp_body_weight: this.bodyweight,
          bp_baggage_weight: this.baggageweight,
          bp_remark: this.remark,
          bp_inter_field: this.checkboxinterfield,
          retire: 0,
        };

        await this.Updatetodb(this.listPassenger[this.chooseItemPassengerIndex].IdbookingPass, dataUpdate);
        //this.DataPassengerFromDB(this.bookingId);
      }
      this.SumDataPassenger();

      this.$emit("addPassengerInboundSuccess", null);
    },
    async SumDataPassenger() {
      //Add
      this.sumPassenger = 0;

      this.sumbodyweight = 0;
      this.sumbaggageweight = 0;
      this.sumtotal = 0;
      this.listPassenger.forEach((element) => {
        //Add
        if (element.retire != "delete") {
          this.sumPassenger += 1;
          if (element.bodyweight != null) {
            this.sumbodyweight += parseFloat(element.bodyweight);
          }
          if (element.baggageweight != null) {
            this.sumbaggageweight += parseFloat(element.baggageweight);
          }
        }
      });
      this.sumtotal =
        parseFloat(this.sumbodyweight) + parseFloat(this.sumbaggageweight);
    },
    async DeleteItem(itemPass) {
      try {
        //Edit
        await feathersClientUOA.service("bookingpassenger").remove(itemPass.IdbookingPass);
        await this.DataPassengerFromDB(this.bookingId);
        this.SumDataPassenger();
        this.$emit("addPassengerInboundSuccess", null);
        //SEQ LOG
        let properties = {
          b_id: parseInt(this.bookingId),
          bp_id: parseInt(itemPass.IdbookingPass)
        };
        this.$log.Info('{action} by {username} ({role}) : b_id={b_id}, bp_id={bp_id}', { action: "DeleteInboundPassengerFromBooking", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("DeleteInboundPassengerFromBooking, Error : {error}", { error });
        console.log("DeleteInboundPassengerFromBooking, Error : {error}" + error);
      }
    },
    async CreateBookingPassengerRawData(Id) {
      try {
        this.listPassenger.forEach((element) => {
          var checkboxinterfield = 0;
          if (element.checkboxinterfield == true) {
            checkboxinterfield = 1;
          } else {
            checkboxinterfield = 0;
          }
          var DataAdd = {
            u_id: element.u_id,
            b_id: Id,
            ap_departairport_id: element.ap_departairport_id,
            ap_destairport_id: element.ap_destairport_id,
            pt_id: 2,
            bp_body_weight: element.bodyweight,
            bp_baggage_weight: element.baggageweight,
            bp_remark: element.remark,
            bp_inter_field: checkboxinterfield,
          };
          this.CreateBookingPassenger(DataAdd);
        });
      } catch (error) {
        console.log(error);
      }
    },
    async CreateBookingPassenger(DataAdd) {
      try {
        var res = await feathersClientUOA.service("bookingpassenger").create(DataAdd);
        //SEQ LOG
        let properties = {
          b_id: parseInt(DataAdd.b_id),
          bp_id: parseInt(res.bp_id),
          u_id: parseInt(DataAdd.u_id),
          pt_id: DataAdd.pt_id,
          bp_remark: DataAdd.bp_remark,
          ap_departairport_id: DataAdd.ap_departairport_id,
          ap_destairport_id: DataAdd.ap_destairport_id,
          bp_inter_field: DataAdd.bp_inter_field,
          bp_body_weight: DataAdd.bp_body_weight,
          bp_baggage_weight: DataAdd.bp_baggage_weight
        };
        this.$log.Info('{action} by {username} ({role}) : b_id={b_id}, bp_id={bp_id}, u_id={u_id}, ap_departairport_id={ap_departairport_id}, ap_destairport_id={ap_destairport_id}', { action: "AddInboundPassengerToBooking", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("AddInboundPassengerToBooking, Error : {error}", { error });
        console.log("AddInboundPassengerToBooking, Error : {error}" + error);
      }
    },
    async DataPassengerFromDB(idboking) {
      this.itemsDataPassenger = [];
      this.listPassenger = [];
      try {
        var q = {};
        q.b_id = idboking;
        q.pt_id = 2;
        //q.retire =1;
        var res = await feathersClientUOA
          .service("viewbookingpassenger")
          .find({ query: q });
        this.itemsDataPassenger = res.data;
        this.itemsDataPassenger.forEach((element) => {
          var datachoosepassenger = {};
          datachoosepassenger["IdbookingPass"] = element.bp_id;
          datachoosepassenger["u_id"] = element.u_id;
          datachoosepassenger["FullName"] =
            element.u_firstname + " " + element.u_lastname;
          datachoosepassenger["c_name"] = element.c_name;
          datachoosepassenger["u_imageurl"] = element.u_imageurl;
          datachoosepassenger["remark"] = element.bp_remark;
          datachoosepassenger["ap_departairport_id"] =
            element.ap_departairport_id;
          datachoosepassenger["departurefrom"] = element.ap_departairport_name;
          datachoosepassenger["ap_destairport_id"] = element.ap_destairport_id;
          datachoosepassenger["destination"] = element.ap_destairport_name;
          if (element.bp_inter_field == 1) {
            datachoosepassenger["checkboxinterfield"] = true;
          } else {
            datachoosepassenger["checkboxinterfield"] = false;
          }
          datachoosepassenger["bodyweight"] = element.bp_body_weight;
          datachoosepassenger["baggageweight"] = element.bp_baggage_weight;
          datachoosepassenger["retire"] = element.retire == 1 ? "delete" : "";

          //Add
          datachoosepassenger["SafetyTrainingDayLeft"] =
            element.SafetyTrainingDayLeft;
          datachoosepassenger["MedicalCheckupDayLeft"] =
            element.MedicalCheckupDayLeft;

          this.listPassenger.push(datachoosepassenger);
          this.SumDataPassenger();
        });
      } catch (error) {
        console.log(error);
      }

      return this.listPassenger.length;
    },
    async Updatetodb(IdbookingPass, dataUpdate) {
      try {
        await feathersClientUOA.service("bookingpassenger").patch(IdbookingPass, dataUpdate);
        //SEQ LOG
        let properties = {
          b_id: parseInt(this.bookingId),
          bp_id: parseInt(IdbookingPass),
          pt_id: dataUpdate.pt_id,
          bp_remark: dataUpdate.bp_remark,
          ap_departairport_id: dataUpdate.ap_departairport_id,
          ap_destairport_id: dataUpdate.ap_destairport_id,
          bp_inter_field: dataUpdate.bp_inter_field,
          bp_body_weight: dataUpdate.bp_body_weight,
          bp_baggage_weight: dataUpdate.bp_baggage_weight
        };
        this.$log.Info('{action} by {username} ({role}) : b_id={b_id}, bp_id={bp_id}, ap_departairport_id={ap_departairport_id}, ap_destairport_id={ap_destairport_id}', { action: "EditInboundPassengerInBooking", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("EditInboundPassengerInBooking, Error : {error}", { error });
        console.log("EditOutboundPassengerInBooking, Error : {error}" + error);
      }
    },
    async Recover(itemPass, index) {
      this.modeEvent = "edit";
      this.arraychoospassenger = [
        {
          u_id: itemPass.u_id,
          FullName: itemPass.FullName,
          c_name: itemPass.c_name,
          u_imageurl: itemPass.u_imageurl,
        },
      ];
      var datachoosepassenger = {};
      datachoosepassenger["IdbookingPass"] = itemPass.IdbookingPass;
      datachoosepassenger["u_id"] = itemPass.u_id;
      datachoosepassenger["FullName"] = itemPass.FullName;
      datachoosepassenger["c_name"] = itemPass.c_name;
      datachoosepassenger["u_imageurl"] = itemPass.u_imageurl;
      datachoosepassenger["remark"] = itemPass.remark;
      datachoosepassenger["ap_departairport_id"] = itemPass.ap_departairport_id;
      datachoosepassenger["departurefrom"] = itemPass.departurefrom;
      datachoosepassenger["ap_destairport_id"] = itemPass.ap_destairport_id;
      datachoosepassenger["destination"] = itemPass.destination;
      datachoosepassenger["checkboxinterfield"] = itemPass.checkboxinterfield;
      datachoosepassenger["bodyweight"] = itemPass.bodyweight;
      datachoosepassenger["baggageweight"] = itemPass.baggageweight;
      datachoosepassenger["retire"] = 0;

      //Add
      datachoosepassenger["SafetyTrainingDayLeft"] =
        itemPass.SafetyTrainingDayLeft;
      datachoosepassenger["MedicalCheckupDayLeft"] =
        itemPass.MedicalCheckupDayLeft;

      this.listPassenger[index] = datachoosepassenger;

      //Add
      this.SumDataPassenger();
    },

    //Add
    GotoStepDialog2() {
      if (this.arraychoospassenger[0].FullName != "") {
        this.stepdialog = 2;
      }
    },

    async SearchPassenger() {
      try {
        if (this.search != "") {
          const q = {};
          //ยกเว้น flight เห็นหมดทุกคน
          if (this.userdata.r_id == 2) {
            q.o_id = this.userdata.o_id;
          }
          q.r_id = 3;
          q.u_firstname = { $like: this.search + "%" };

          let res = await feathersClientUOA
            .service("viewuser")
            .find({ query: q });
          this.userItem = res.data;
        }
      } catch (err) {}
    },
  },
};
</script>

<style >
</style>
